import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { LegalEntityDto } from './legal-entity.dto';
import { LegalEntity, LegalEntityCheUidPostBody, LegalEntityPostBody } from './legal-entity.model';
import { map, Observable } from 'rxjs';
import { mapLegalEntityDtoToLegalEntity } from './legal-entity.mapper';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LegalEntityResource extends BaseHttpResource<
  LegalEntityDto,
  CoreListEnvelop<LegalEntityDto>,
  LegalEntityPostBody
> {
  constructor() {
    super('legal-entity');
  }

  patchState({
    id,
    state,
    _version,
  }: {
    id: LegalEntity['id'];
    state: LegalEntity['state'];
    _version: LegalEntity['_version'];
  }): Observable<LegalEntity> {
    return this.update(id, { state, _version }, 'patch').pipe(
      map((legalEntityDto) => mapLegalEntityDtoToLegalEntity(legalEntityDto))
    );
  }

  checkIsClaimed(legalEntity: Pick<LegalEntity, 'externalId'>): Observable<null> {
    const headers = new HttpHeaders({
      maxRetry: 1,
    });
    return this.http.post<null>(
      `${this.url}/check-is-claimed`,
      {
        cheUid: legalEntity.externalId,
      },
      { headers }
    );
  }

  createByCheUid(claimCompanyDto: LegalEntityCheUidPostBody): Observable<LegalEntityDto> {
    return this.http.post<LegalEntityDto>(`${this.url}/create-by-cheuid`, claimCompanyDto);
  }
}
