import { WalletDto } from './wallet.dto';

export enum WalletType {
  Private = 'WALLET_TYPE_PRIVATE',
  Shared = 'WALLET_TYPE_SHARED',
  Received = 'WALLET_TYPE_RECEIVED',
}

export enum WalletAccessRole {
  Owner = 'WALLET_ACCESS_ROLE_OWNER',
  User = 'WALLET_ACCESS_ROLE_USER',
}

export enum WalletState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export interface Wallet extends WalletDto {
  type: WalletType;
}
