import { authActions } from '@mkp/auth/actions';
import { objectKeys } from '@mkp/shared/util-format';
import { passwordlessLoginActions } from '@mkp/user/feature-passwordless-login/actions';
import { Action, ActionReducer, createFeature, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { loginActions } from '@mkp/user/feature-login/actions';
import { transientAppCacheActions } from '@mkp/transient-app-cache/actions';
import { UserLoginMethodType } from '@mkp/tracking/feature-tealium';

export enum TransientAppCacheItems {
  loginEmail = 'loginEmail',
  loginStarted = 'loginStarted',
  loginMethod = 'loginMethod',
  companyWasClaimed = 'companyWasClaimed',
}

export interface TransientAppCacheState {
  items: {
    [TransientAppCacheItems.loginEmail]: string | undefined;
    [TransientAppCacheItems.loginStarted]: Date | undefined;
    [TransientAppCacheItems.companyWasClaimed]: Date | undefined;
    [TransientAppCacheItems.loginMethod]: UserLoginMethodType | undefined;
  };
}

export const getInitialState = (): TransientAppCacheState => ({
  items: {
    [TransientAppCacheItems.loginEmail]: undefined,
    [TransientAppCacheItems.loginStarted]: undefined,
    [TransientAppCacheItems.companyWasClaimed]: undefined,
    [TransientAppCacheItems.loginMethod]: undefined,
  },
});

const reducer = createReducer(
  getInitialState(),
  on(loginActions.usernamePasswordSubmitted, (state) => ({
    ...state,
    items: {
      ...state.items,
      [TransientAppCacheItems.loginStarted]: new Date(),
      [TransientAppCacheItems.loginMethod]: 'password' as UserLoginMethodType,
    },
  })),
  on(transientAppCacheActions.companyWasClaimed, (state) => ({
    ...state,
    items: { ...state.items, [TransientAppCacheItems.companyWasClaimed]: new Date() },
  })),
  on(passwordlessLoginActions.linkRequested, (state, { email }) => ({
    ...state,
    items: {
      ...state.items,
      [TransientAppCacheItems.loginEmail]: email,
      [TransientAppCacheItems.loginStarted]: new Date(),
      [TransientAppCacheItems.loginMethod]: 'email' as UserLoginMethodType,
    },
  })),
  on(transientAppCacheActions.companyWasClaimedUsed, (state) => ({
    ...state,
    items: { ...state.items, [TransientAppCacheItems.companyWasClaimed]: undefined },
  })),
  on(transientAppCacheActions.loginStartedUsed, (state) => ({
    ...state,
    items: { ...state.items, [TransientAppCacheItems.loginStarted]: undefined },
  })),
  on(authActions.userIsVerified, (state) => ({
    // remove loginEmail after successful login
    ...state,
    items: { ...state.items, [TransientAppCacheItems.loginEmail]: undefined },
  }))
);

export const transientAppCacheFeature = createFeature({
  name: 'transientAppCacheState',
  reducer,
});

const getKeysToSync = (): TransientAppCacheItems[] => objectKeys(getInitialState().items);

export const transientAppCacheReducer: MetaReducer = (
  reducer: ActionReducer<TransientAppCacheState, Action>
) =>
  localStorageSync({
    keys: [{ [transientAppCacheFeature.name]: [{ items: getKeysToSync() }] }],
    storageKeySerializer: (key) => `jobcloud.${key}`,
    rehydrate: true,
  })(reducer);
