import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { CreditResource, HIGH_LIMIT, isSlotType } from '@mkp/shared/data-access';
import { creditManagementPageActions } from '@mkp/credit/actions';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { creditApiActions } from '@mkp/credit/actions';
import { CreditOverviewActions } from '@mkp/credit/feature-credit-overview/actions';
import { ProductOfferingActions } from '@mkp/product/feature-product-offering/actions';
import { productSummaryPageActions } from '@features/product-summary/store/actions';
import { PublicationStorePageActions } from '@mkp/publication/feature-publication-store/actions';
import { FeatureDashboardActions } from '@mkp/user/feature-dashboard/actions';
import { selectSelectedAccountId } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { formatISO9075 } from 'date-fns';
import { SnackbarService } from '@mkp/shared/ui-library';
import { listVacancyActions } from '@app/features/vacancy/store/actions';

export const addCredit = createEffect(
  (actions$ = inject(Actions), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(creditApiActions.addCredit),
      switchMap(({ credit }) =>
        creditResource.add(credit).pipe(
          map(() => creditApiActions.addCreditSucceed()),
          catchError((error) => of(creditApiActions.addCreditFailed({ error })))
        )
      )
    ),
  { functional: true }
);

export const addCreditSuccess = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(creditApiActions.addCreditSucceed),
      tap(() => snackbarService.show('ADD_CREDIT.SNACKBAR.SUCCESS'))
    ),
  { functional: true, dispatch: false }
);

export const addCreditFailure = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(creditApiActions.addCreditFailed),
      tap(() => snackbarService.showError('ADD_CREDIT.SNACKBAR.ERROR'))
    ),
  { functional: true, dispatch: false }
);

/**
 * @deprecated will be replaced by the new credit management
 * when credit will get mandatory wallet id.
 */
export const loadAvailableCredits = createEffect(
  (actions$ = inject(Actions), store = inject(Store), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(
        CreditOverviewActions.enter,
        ProductOfferingActions.enter,
        productSummaryPageActions.enter,
        PublicationStorePageActions.enter,
        listVacancyActions.load,
        listVacancyActions.loadCredits,
        FeatureDashboardActions.enter
      ),
      switchMap(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
      switchMap((selectedAccountId) => {
        return creditResource.getWithQuery(getCreditsQuery(selectedAccountId, HIGH_LIMIT)).pipe(
          map((response) => response._embedded.results),
          map((credits) => creditApiActions.loadCreditsSucceed({ credits })),
          catchError((error) => of(creditApiActions.loadCreditsFailed({ error })))
        );
      })
    ),
  { functional: true }
);

export const loadAvailableCreditsWithWallets = createEffect(
  (actions$ = inject(Actions), store = inject(Store), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(
        creditManagementPageActions.enter,
        creditManagementPageActions.loadCredits,
        listVacancyActions.load,
        ProductOfferingActions.enter
      ),
      switchMap(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
      switchMap((selectedAccountId) => {
        return creditResource.getWithQuery(getCreditsQuery(selectedAccountId, HIGH_LIMIT)).pipe(
          map((response) => response._embedded.results),
          map((credits) =>
            credits.filter(
              (credit) => credit._availableCredits > 0 || isSlotType(credit.product.type)
            )
          ),
          map((credits) => creditApiActions.loadCreditsSucceed({ credits })),
          catchError((error) => of(creditApiActions.loadCreditsFailed({ error })))
        );
      })
    ),
  { functional: true }
);

export const loadCreditTotalCount = createEffect(
  (actions$ = inject(Actions), creditResource = inject(CreditResource)) =>
    actions$.pipe(
      ofType(creditApiActions.loadCreditTotalCount),
      switchMap(() =>
        creditResource
          .getWithQuery({
            limit: 0,
          })
          .pipe(
            map(({ totalCount }) => creditApiActions.loadCreditTotalCountSucceed({ totalCount })),
            catchError((error) => of(creditApiActions.loadCreditTotalCountFailed({ error })))
          )
      )
    ),
  { functional: true }
);

export const loadCreditCount = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(creditManagementPageActions.enter),
      map(() => creditApiActions.loadCreditTotalCount())
    ),
  { functional: true }
);

function getCreditsQuery(accountId?: string, limit = 0) {
  const keys = [
    accountId && `wallet.walletAccesses.account.id==${accountId}`,
    '_availableCredits>0',
    `validTo>${formatISO9075(new Date())}`,
  ].filter(Boolean);
  return { filter: keys.join(';'), limit };
}
