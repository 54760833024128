import { createSelector } from '@ngrx/store';
import { transientAppCacheFeature } from './transient-app-cache.reducer';

const { selectItems } = transientAppCacheFeature;

export const selectLoginEmail = createSelector(selectItems, (items) => items.loginEmail);

export const selectLoginStarted = createSelector(selectItems, (items) => items.loginStarted);

export const selectCompanyWasClaimed = createSelector(
  selectItems,
  (items) => items.companyWasClaimed
);

export const selectLoginMethod = createSelector(selectItems, (items) => items.loginMethod);
