import { Address, CheUID, Industry, Revision, RevisionEmbed } from '@mkp/shared/data-access';

export interface LegalEntity extends Revision, RevisionEmbed {
  id: string;
  externalSource: string;
  externalId: CheUID;
  name: string;
  state: LegalEntityState;
  employeeCountFrom: number;
  employeeCountTo: number;
  industry: Industry['name'];
  claimed: boolean;
  industryId: string;
  address: Address;
  _version: string;
}

export enum LegalEntityState {
  InVerification = 'IN_VERIFICATION',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  OnHold = 'ON_HOLD',
}

export type OrganisationEntry = Pick<LegalEntity, 'externalId' | 'name' | 'address' | 'state'> & {
  _type: 'organisationEntry';
};

export type CommercialRegisterEntry = Pick<
  LegalEntity,
  'externalId' | 'name' | 'address' | 'state' | 'employeeCountFrom' | 'employeeCountTo' | 'industry'
> & {
  _type: 'commercialRegisterEntry';
};

export type LegalEntityCheUidPostBody = {
  cheUid: LegalEntity['externalId'];
  industryId?: Industry['id'];
  employeeCountFrom?: number;
  employeeCountTo?: number;
};

export type LegalEntityPostBody = {
  externalSource: LegalEntity['externalSource'];
  externalId?: LegalEntity['externalId'];
  name: LegalEntity['name'];
  state: LegalEntity['state'];
  address: LegalEntity['address'];
  employeeCountFrom: LegalEntity['employeeCountFrom'];
  employeeCountTo: LegalEntity['employeeCountTo'];
  industryId: LegalEntity['industryId'];
};
