import { WalletAccessDto, WalletDto } from './wallet.dto';
import { WalletAccessRole } from './wallet.model';
import { AddWorkspacesInput } from './wallet.resource';

export function generateWorkspacesUpdateBody(input: AddWorkspacesInput): Partial<WalletDto> {
  const { wallet, currentAccount, selectedAccounts } = input;

  return {
    walletAccesses: [
      {
        accountId: currentAccount,
        role: WalletAccessRole.Owner,
      },
      ...selectedAccounts.map(
        (id) =>
          ({
            accountId: id,
            role: WalletAccessRole.User,
          }) as WalletAccessDto<WalletAccessRole.User>
      ),
    ],
    _version: wallet._version,
  };
}
