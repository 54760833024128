import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import { SelectConfig } from './select.config';

@Component({
  selector: 'mds-select',
  templateUrl: 'select.template.html',
  styleUrls: ['../input-wrapper/input-wrapper.template.scss', './select.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent extends AbstractInputComponent<SelectConfig> {}
