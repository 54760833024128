import { Mention } from '@mkp/text-editor/models';

export enum AtsMentionsIds {
  FirstName = 'firstName',
  LastName = 'lastName',
  Platform = 'platform',
}

export const ATS_MENTIONS: Mention<AtsMentionsIds>[] = [
  { id: AtsMentionsIds.FirstName, text: 'APPLICATION.MODAL.DECLINE.MENTION.FIRST_NAME' },
  { id: AtsMentionsIds.LastName, text: 'APPLICATION.MODAL.DECLINE.MENTION.LAST_NAME' },
  { id: AtsMentionsIds.Platform, text: 'APPLICATION.MODAL.DECLINE.MENTION.PLATFORM' },
];
