import { filter, map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { AppConfig } from '@config/app.config';
import { VacancyResource } from '@mkp/vacancy/data-access';
import { Store } from '@ngrx/store';
import { selectFirstCompanyByAccountId } from '@company/store/selectors';
import { selectAccountMemberships } from '@mkp/account-membership/state';
import { selectActiveAccounts } from '@mkp/account/state';

export const ROUTS_WITHOUT_SELECTED_ACCOUNT = [AppConfig.routes.supportCenter, AppConfig.routes.me];
export const isVacancyUrl = (url: string) => url.includes(AppConfig.routes.vacancy);
const uuidRegex =
  /\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-4[0-9a-fA-F]{3}\b-[89abAB][0-9a-fA-F]{3}\b-[0-9a-fA-F]{12}\b/;

export const getSelectWorkspacePayload = (accountId: string, store: Store) => {
  return combineLatest({
    accountId: findAccountId(accountId, store),
    companyProfileId: findCompanyProfileId(accountId, store),
    accountMembershipId: findAccountMembershipId(accountId, store),
  }).pipe(take(1));
};

const findCompanyProfileId = (accountId: string, store: Store) =>
  store.select(selectFirstCompanyByAccountId(accountId)).pipe(
    filter(Boolean),
    map((company) => company.id),
    take(1)
  );
const findAccountId = (accountId: string, store: Store) =>
  store.select(selectActiveAccounts).pipe(
    map((accounts) => accounts.find((account) => account.id === accountId)),
    map((account) => account?.id),
    filter(Boolean),
    take(1)
  );
const findAccountMembershipId = (accountId: string, store: Store) =>
  store.select(selectAccountMemberships).pipe(
    map((memberships) => memberships.find((membership) => membership.accountId === accountId)),
    map((membership) => membership?.id),
    filter(Boolean),
    take(1)
  );

export const fetchVacancyFromUrl = (
  vacancyResource: VacancyResource,
  url: string
): Observable<{ accountId: string }> =>
  vacancyResource.getById(getVacancyId(url)).pipe(filter(Boolean));

export const isBoUserFn = (isBoUser: boolean | null) => isBoUser !== null && isBoUser;
export const hasVacancyId = (url: string) => isVacancyUrl(url) && uuidRegex.test(url);
export const getVacancyId = (url: string) => {
  const match = url.match(uuidRegex);
  return match ? match[0] : '';
};
