<div
  *ngIf="config"
  class="select-wrapper"
  [class.disabled]="config?.disabled"
  [class.has-errors]="(isInvalid$ | async) || hasErrors"
>
  <div *ngIf="config?.labelText || config?.topCaption" class="top-wrapper">
    <label class="body-s label-text semibold">{{ config?.labelText | translate }}</label>
    <span class="top-caption">{{ config?.topCaption | translate }}</span>
  </div>
  <div #wrapper class="input-wrapper" [class.disabled]="config?.disabled">
    <mat-select
      disableRipple="true"
      disableOptionCentering="true"
      hideSingleSelectionIndicator="true"
      panelClass="select-custom"
      [formControl]="control"
      [placeholder]="config?.placeholder"
      [panelWidth]="wrapper.clientWidth + 4"
      [mkpDataTest]="config?.dataTest"
    >
      <mat-option
        *ngFor="let option of config.options; index as i"
        [value]="option.id"
        mkpDataTest="{{config?.dataTest}}-{{i}}"
      >
        <span [innerHtml]="option.name | translate"></span>
      </mat-option>
    </mat-select>
  </div>
  <div *ngIf="bottomCaption$ | async as bottomCaption" class="bottom-wrapper">
    <span class="bottom-caption">{{ bottomCaption | translate }}</span>
  </div>
</div>
