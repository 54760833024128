import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationStatus } from '@mkp/application/models';
import { BaseHttpResource, CoreListEnvelop, QueryParams } from '@mkp/shared/data-access';
import { map, Observable } from 'rxjs';
import { ApplicationStatusDto } from './application-status.dto';
import { mapApplicationStatusDtoToModel } from './application-status.mapper';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusResource extends BaseHttpResource<
  ApplicationStatusDto,
  CoreListEnvelop<ApplicationStatusDto>,
  ApplicationStatus
> {
  constructor() {
    super('application-status');
  }

  list(accountId: string): Observable<ApplicationStatus[]> {
    return this.http
      .get<
        CoreListEnvelop<ApplicationStatusDto>
      >(getApplicationStatusUrl(this.uri), { params: getApplicationStatusParams(accountId) })
      .pipe(
        map(({ _embedded: { results: applicationStatusDtos } }) =>
          applicationStatusDtos.map(mapApplicationStatusDtoToModel)
        )
      );
  }
}

const getApplicationStatusUrl = (uri: string): string => `${environment.api.ats}/${uri}`;
const getApplicationStatusParams = (accountId: string): QueryParams => ({
  filter: `account.id==${accountId}`,
  sort: 'step=asc',
});
