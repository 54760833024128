import { createActionGroup, emptyProps } from '@ngrx/store';

export const transientAppCacheActions = createActionGroup({
  source: 'App Cache',
  events: {
    'Login Started Used': emptyProps(),
    'Company Was Claimed': emptyProps(),
    'Company Was Claimed Used': emptyProps(),
  },
});
