import { createSelector } from '@ngrx/store';
import { CreditDto } from '@mkp/shared/data-access';
import { ProductCode, SlotCode } from '@mkp/shared/util-product';
import { creditFeature } from './credit.reducer';
import {
  SlotStats,
  SlotOption,
  isCreditSlot,
  calculateUsedSlots,
  calculateAvailableSlots,
  getSlotCode,
  getSlotStatsByFilter,
  createSlotOption,
} from './slot-helper';

const { selectAll: selectCredits } = creditFeature;

/**
 * Creates a map of slot statistics for each slot code
 * @description For every slot, calculates the total used and available slots
 */
export const selectSlotStatsMap = createSelector(
  selectCredits,
  (credits: CreditDto[]): Map<SlotCode, SlotStats> => {
    const statsMap = new Map<SlotCode, SlotStats>();

    credits.forEach((credit) => {
      const slotCode = getSlotCode(credit);
      if (!slotCode) return;

      const available = calculateAvailableSlots(credit);
      const used = calculateUsedSlots(credit);
      const existingStats = statsMap.get(slotCode) || { used: 0, available: 0 };

      statsMap.set(slotCode, {
        used: existingStats.used + used,
        available: existingStats.available + available,
      });
    });

    return statsMap;
  }
);

/**
 * Returns slot statistics filtered by slot code or 'All'
 * @param slotFilter The filter to apply ('All' or a specific SlotCode)
 * @returns Selector that provides filtered slot statistics
 */
export const selectSlotStatsBySlotFilter = (slotFilter: SlotCode | 'All' | null) =>
  createSelector(
    selectSlotStatsMap,
    (statsMap): SlotStats => getSlotStatsByFilter(statsMap, slotFilter)
  );

/**
 * Returns the total number of available slots across all slot types
 * @description Selector that returns the total number of available slots across all slot types
 */
export const selectTotalAvailableSlots = createSelector(selectSlotStatsMap, (statsMap): number => {
  const { available } = getSlotStatsByFilter(statsMap, 'All');
  return available;
});

/**
 * Returns a list of slot options with usage statistics
 * @description Provides options for all slots that have at least one total slot
 */
export const selectAvailableSlotOptions = createSelector(
  selectSlotStatsMap,
  (statsMap): SlotOption[] => {
    const options: SlotOption[] = [];

    statsMap.forEach((stats, code) => {
      const total = stats.used + stats.available;

      if (total > 0) {
        options.push(createSlotOption(code, stats));
      }
    });

    return options;
  }
);

/**
 * Finds the ID of the oldest available credit with the specified slot code
 * @param slotCode The slot code to search for
 * @returns Selector that provides the ID of the oldest available credit with the specified slot code
 */
export const selectAvailableSlotId = (slotCode: SlotCode) =>
  createSelector(selectCredits, (credits: CreditDto[]): string | undefined => {
    // Filter credits to find available slots of the specified type
    const availableSlots = credits.filter(
      (credit) =>
        isCreditSlot(credit) &&
        credit.product.type === (slotCode as unknown as ProductCode) &&
        calculateAvailableSlots(credit) > 0
    );

    // Sort by creation date (oldest first)
    const sortedSlots = [...availableSlots].sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    return sortedSlots.length > 0 ? sortedSlots[0].id : undefined;
  });
