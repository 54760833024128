import { inject, Injectable } from '@angular/core';
import { Vacancy } from '@app/features/vacancy/models/vacancy.model';
import { listVacancyActions } from '@app/features/vacancy/store/actions';
import { vacancyApiActions } from '@app/features/vacancy/store/actions/vacancy-api.actions';
import { CreditStatusActions } from '@mkp/credit/feature-credit-status/actions';
import { PublicationHistoryActions } from '@mkp/publication/feature-publication-history/actions';
import {
  CreditMapper,
  CreditRedemptionDto,
  CreditRedemptionMapper,
  CreditRedemptionResource,
  CreditRedemptionViewModel,
  CreditResource,
  CreditState,
  RefundCredit,
} from '@mkp/shared/data-access';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as VacancyActions from '@vacancy/store/actions/vacancy.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class CreditRedemptionEffects {
  private actions$ = inject(Actions);
  private creditRedemptionResource = inject(CreditRedemptionResource);
  private creditResource = inject(CreditResource);
  private notificationService = inject(SnackbarService);
  private store = inject(Store);

  stopCredit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicationHistoryActions.stopCredit),
        switchMap(({ id, _version }) =>
          this.creditRedemptionResource.update(id, { id, _version, stoppedAt: 'now' }).pipe(
            tap(() =>
              this.notificationService.show('CREDIT_REDEMPTION.STOP_CREDIT_SUCCESS_MESSAGE')
            ),
            map((response) => CreditRedemptionMapper.toViewModel(response)),
            map((credit: CreditRedemptionViewModel) =>
              PublicationHistoryActions.stopCreditSuccess(credit)
            ),
            tap((credit) => {
              this.store.dispatch(VacancyActions.GetVacancy({ vacancyId: credit.vacancyId }));
            })
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  reloadCredits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicationHistoryActions.stopCreditSuccess),
        tap(() => listVacancyActions.loadCredits())
      ),
    { useEffectsErrorHandler: false, dispatch: false }
  );

  refundCredit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicationHistoryActions.refundCredit),
        switchMap(({ creditId, id, _creditRedeemVersion, _creditVersion }: RefundCredit) =>
          this.creditResource
            .update(creditId, { id: creditId, _version: _creditVersion, state: CreditState.Refund })
            .pipe(
              map((credit) => CreditMapper.toViewModel(credit)),
              switchMap((credit) => {
                this.notificationService.show('CREDIT_REDEMPTION.STOP_CREDIT_SUCCESS_MESSAGE');
                return [
                  PublicationHistoryActions.refundCreditSuccess({ id, credit }),
                  PublicationHistoryActions.stopCredit({
                    id,
                    _version: _creditRedeemVersion,
                  }),
                ];
              }),
              catchError((error) => {
                this.notificationService.show('REQUEST_TIMEOUT_MESSAGE', {
                  state: ActionState.Error,
                });
                return of(PublicationHistoryActions.refundCreditError(error));
              })
            )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  loadCreditRedemptionsFromVacancy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          vacancyApiActions.loadVacancyFromGuardSuccess,
          VacancyActions.GetVacancySuccess,
          VacancyActions.CreateVacancySuccess
        ),
        map(({ vacancy }) =>
          vacancy._embedded.creditRedemptions.map((credit) =>
            CreditRedemptionMapper.toViewModel(credit)
          )
        ),
        map((credits) => CreditStatusActions.loadCreditsSuccess({ credits }))
      ),
    { useEffectsErrorHandler: false }
  );

  loadCreditRedemptionsFromVacancies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vacancyApiActions.listVacanciesSuccess, vacancyApiActions.loadMoreVacanciesSuccess),
      map(({ vacancies }) =>
        aggregateCreditRedeems(vacancies).map((credit) =>
          CreditRedemptionMapper.toViewModel(credit)
        )
      ),
      map((credits) => CreditStatusActions.loadCreditsSuccess({ credits }))
    )
  );
}

// aggregate and flatten the creditRedemptions from multiple vacancies
const aggregateCreditRedeems = (vacancies: Vacancy[]): CreditRedemptionDto[] => {
  return vacancies
    .map((vacancy) => vacancy._embedded.creditRedemptions)
    .reduce((acc, cur) => [...acc, ...cur], []);
};
