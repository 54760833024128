import { LegalEntity } from '@mkp/legal-entity/data-access';
import { UUID } from '@mkp/shared/data-access';

export const ATS_NAMES = ['INTERNAL', 'EXTERNAL_URL', 'KOMBO'] as const;
export type SettingsAtsName = (typeof ATS_NAMES)[number];

export enum AccountState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export interface Account {
  id: UUID;
  name: string;
  settings: AccountSettings;
  legalEntity: LegalEntity;
  legalEntityId?: string;
  state: AccountState;
  computed: {
    canCreateUser: boolean;
  };
  createdAt: Date;
  _version: string;
}

export interface AccountSettings {
  ats: AccountSettingsAts;
}

interface AccountSettingsAts {
  name: SettingsAtsName;
}
