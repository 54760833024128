import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SkeletonLoaderComponent } from './skeleton-loader.component';

/**
 * @deprecated please use the standalone SkeletonLoaderComponent in nx libs
 */
@NgModule({
  declarations: [SkeletonLoaderComponent],
  imports: [CommonModule],
  exports: [SkeletonLoaderComponent],
})
export class SkeletonLoaderModule {}
