import { AccountState, isAccount } from '@mkp/account/data-access';
import { LegalEntityState } from '@mkp/legal-entity/data-access';
import { LoadingState } from '@mkp/shared/util-state';
import { createSelector } from '@ngrx/store';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { accountFeature } from './account.reducer';

const {
  selectEntities: selectAccountEntities,
  selectSelectedAccountId,
  selectAll: selectAllAccounts,
  selectFetchState,
} = accountFeature;

export { selectAccountEntities, selectAllAccounts, selectSelectedAccountId };

export const selectActiveAccounts = createSelector(selectAllAccounts, (accounts) =>
  accounts.filter((account) => account.state === AccountState.Active)
);

export const selectAccountById = (accountId: string | undefined) =>
  createSelector(accountFeature.selectEntities, (accounts) =>
    accountId ? accounts[accountId] : undefined
  );

export const selectSelectedAccount = createSelector(
  selectAccountEntities,
  selectSelectedAccountId,
  (entities, selectedAccountId) => (selectedAccountId ? entities[selectedAccountId] : undefined)
);

export const selectLegalEntityNameFromVacancy = (vacancy: Vacancy) =>
  createSelector(selectLegalEntityFromVacancy(vacancy), (legalEntity) => legalEntity?.name);

export const selectAccountsByIds = (accountIds: string[]) =>
  createSelector(selectAccountEntities, (accounts) =>
    accountIds.map((accountId) => accounts[accountId]).filter(Boolean)
  );

export const selectHasSelectedAccount = createSelector(
  selectSelectedAccount,
  (selectSelectedAccount) => !!selectSelectedAccount
);

export const selectIsLegalEntityBeingVerified = createSelector(
  selectSelectedAccount,
  // selectedAccount can be null for multi-user accounts. We return false in this case
  (account): boolean =>
    isAccount(account) &&
    [LegalEntityState.InVerification, LegalEntityState.OnHold].includes(account.legalEntity.state)
);

export const selectAccountIsLoading = createSelector(
  selectFetchState,
  (fetchState) => fetchState === LoadingState.LOADING
);

export const selectAccountAtsName = createSelector(
  selectSelectedAccount,
  (account) => account?.settings.ats.name
);

const selectAccountFromVacancy = ({ accountId }: Vacancy) => selectAccountById(accountId);
const selectLegalEntityFromVacancy = (vacancy: Vacancy) =>
  createSelector(selectAccountFromVacancy(vacancy), (account) => account?.legalEntity);
