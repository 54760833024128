import { ApplicationStatus } from './application-status';
import { ApplicationStep, isApplicationStep } from './application-step';

export type ApplicationTab = Exclude<ApplicationStep, ApplicationStep.Declined>;

export const isApplicationTab = (value: number): value is ApplicationTab => {
  return isApplicationStep(value) && value !== ApplicationStep.Declined;
};

export const isUnsuitableTab = (tab: ApplicationTab): boolean => tab === ApplicationStep.Unsuitable;

export const getTab = (step: ApplicationStep): ApplicationTab =>
  step === ApplicationStep.Declined ? ApplicationStep.Unsuitable : step;

export const getStatusIdsForTab = (tab: ApplicationTab, statuses: ApplicationStatus[]): string[] =>
  statuses.filter(({ step }) => getSteps(tab).includes(step)).map(({ id }) => id);

const getSteps = (tab: ApplicationTab): ApplicationStep[] =>
  tab === ApplicationStep.Unsuitable
    ? [ApplicationStep.Unsuitable, ApplicationStep.Declined]
    : [tab];
