import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models/query-options.model';
import { Vacancy } from '../../models/vacancy.model';

export const listVacancyActions = createActionGroup({
  source: 'List Vacancy',
  events: {
    Reset: emptyProps(),
    Load: props<{ query: QueryOptions }>(),
    'Load More': props<{ query: QueryOptions }>(),
    'Load Credits': emptyProps(),
    'Navigate to company from vacancy': props<{ vacancy: Vacancy }>(),
    'Delete Draft Vacancy': props<{ id: string }>(),
  },
});
