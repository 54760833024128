import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  ValidationErrors,
} from '@angular/forms';
import { Observable, Subject, map, merge, of } from 'rxjs';
import { getDefaultFormatter } from './input/input-format/input-format.config';
import { InputConfig } from './input/input.config';
import { RadioButtonConfig } from './radio-button/radio-button.config';
import { SelectConfig } from './select/select.config';

@Component({ template: `` })
export class AbstractInputComponent<C extends InputConfig | RadioButtonConfig | SelectConfig | null>
  implements ControlValueAccessor, OnInit, OnChanges
{
  readonly defaultErrorTranslationKey = 'GENERIC.FORM.INPUT.ERROR';

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;

  /**
   * This control is used in the template, so we can use [formControl] and [formControlName] independently.
   * @see ngOnChanges
   */
  control: FormControl = new FormControl();
  bottomCaption$ = new Observable<string | undefined>();
  isInvalid$ = new Observable<boolean>();
  readonly touchChanges = new Subject<boolean>();

  /**
   * @see onClick
   */
  @ViewChild('input', { static: true }) input!: ElementRef<HTMLInputElement>;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() config?: C;
  @Input() hasErrors!: boolean;
  @Input() formatter = getDefaultFormatter();

  private onTouch = () => {
    // do nothing
  };

  constructor(@Optional() private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.isInvalid$ = merge(
      of(null),
      this.control.statusChanges,
      this.control.valueChanges,
      this.touchChanges
    ).pipe(
      map((hasValueOrTouched) => (hasValueOrTouched ? this.control.status === 'INVALID' : false))
    );

    this.bottomCaption$ = this.isInvalid$.pipe(
      map((touchedAndHasValue) =>
        this.control.errors && touchedAndHasValue
          ? this.getErrorTranslationKey(this.control.errors)
          : this.config?.bottomCaption
      )
    );
  }

  onBlur() {
    this.onTouch();
  }

  handleClear() {
    this.control.setValue('');
    this.control.markAsDirty();
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective.valueAccessor.registerOnTouched(fn);
    }
  }

  registerOnChange(fn: unknown): void {
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective?.valueAccessor.registerOnChange(fn);
    }
  }

  writeValue(obj: unknown): void {
    if (this.formControlDirective && this.formControlDirective.valueAccessor) {
      this.formControlDirective?.valueAccessor.writeValue(obj);
    }
  }

  @HostListener('click')
  onClick() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  ngOnChanges({ formControl, formControlName }: SimpleChanges): void {
    if (formControl) {
      this.setControl(this.formControl);
    }

    if (formControlName && this.controlContainer.control?.get(this.formControlName)) {
      const formControl = this.controlContainer.control.get(this.formControlName) as FormControl;
      this.setControl(formControl);
    }
  }

  private setControl(formControl: FormControl): void {
    const originalMarkAsTouched = formControl.markAsTouched.bind(formControl);
    formControl.markAsTouched = () => {
      originalMarkAsTouched();
      this.touchChanges.next(true);
    };

    this.control = formControl;
  }

  private getErrorTranslationKey(errors: ValidationErrors = {}) {
    if (this.config?.errorTranslations) {
      return this.config?.errorTranslations[Object.keys(errors)[0]];
    }

    const firstErrorKey = Object.keys(errors)
      .filter((k) => errors[k])[0]
      ?.toUpperCase();

    if (this.config?.errorTranslationsKey === '') {
      return firstErrorKey;
    } else {
      return `${
        this.config?.errorTranslationsKey ?? this.defaultErrorTranslationKey
      }.${firstErrorKey}`;
    }
  }
}
