import { CreditRedemptionViewModel, FREE_TRIAL_COUPON } from '@mkp/shared/data-access';
import { Product, ProductCode } from '../models/product.model';
import { isPaid } from './product.helper';
import { ProductPlatformGroup } from '../models/product-offering-feature.model';

export const isFreeTrialProduct = (product: Product): boolean =>
  product.coupon === FREE_TRIAL_COUPON && isPaid(product);

export const isFreeProductCode = (
  productCode: ProductCode
): productCode is ProductCode.JOBSOFFER22FREE | ProductCode.JS24FREE | ProductCode.JOBUP24FREE => {
  return [ProductCode.JOBSOFFER22FREE, ProductCode.JS24FREE, ProductCode.JOBUP24FREE].includes(
    productCode
  );
};

export const isDeprecatedProductCode = (
  productCode: ProductCode
): productCode is Exclude<ProductCode, 'JS24SOLO' | 'JS24PLUS'> =>
  [ProductCode.JS24SOLO, ProductCode.JS24PLUS].includes(productCode);

export const canProductBeUpgraded = (
  creditRedeem: CreditRedemptionViewModel | undefined
): boolean => {
  const productCode = creditRedeem?.information?.productCode as ProductCode;
  const upgradeableProducts = [
    ProductCode.JS24FREE,
    ProductCode.JOBSOFFER22FREE,
    ProductCode.JS24SOLO,
    ProductCode.JS24BASIC,
    ProductCode.JOBSOFFER22XS,
    ProductCode.JOBSOFFER22S,
    ProductCode.JOBSOFFER22M,
    ProductCode.JOBSOFFER22L,
    ProductCode.JOBUP24FREE,
    ProductCode.JOBUP24BASIC,
    ProductCode.JOBUP24LITE,
    ProductCode.JOBUP24ADVANCED,
    ProductCode.JOBUP24ULTIMATE,
  ];
  return upgradeableProducts.includes(productCode);
};

export const isFreeProduct = (creditRedeem: CreditRedemptionViewModel | undefined): boolean => {
  const productCode = creditRedeem?.information?.productCode as ProductCode;
  return isFreeProductCode(productCode);
};

export const isJs24ProductCode = (productType: ProductCode): boolean =>
  [
    ProductCode.JS24FREE,
    ProductCode.JS24SOLO,
    ProductCode.JS24PLUS,
    ProductCode.JS24BASIC,
    ProductCode.JS24ADVANCED,
  ].includes(productType);

export const isJobUpProductCode = (productType: ProductCode): boolean =>
  [
    ProductCode.JOBUP24FREE,
    ProductCode.JOBUP24LITE,
    ProductCode.JOBUP24BASIC,
    ProductCode.JOBUP24ADVANCED,
    ProductCode.JOBUP24ULTIMATE,
    ProductCode.JOBUP24ULTIMATEPLUS,
  ].includes(productType);

export const getProductPlatformGroup = (type: ProductCode): ProductPlatformGroup => {
  if (isJs24ProductCode(type)) {
    return ProductPlatformGroup.JobScout24;
  }

  if (isJobUpProductCode(type)) {
    return ProductPlatformGroup.JobUp;
  }

  return ProductPlatformGroup.Jobs;
};
