<div *ngIf="config && show" class="wrapper" @state [ngClass]="config.className">
  <ui-feedback-header
    [title]="config.title"
    [buttonText]="config.buttonText"
    [state]="config.state"
    [translationParams]="config.translationParams"
    [icon]="icon"
    [isClarityMasked]="config.isClarityMasked"
  />
  <ui-feedback-progress-bar [showAnimation]="true" [duration]="duration" [state]="config.state" />
</div>
