import { accountApiActions } from '@mkp/account/data-access/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { tap } from 'rxjs';

type successMessageActionType = ReturnType<typeof accountApiActions.archiveAccountSuccess>;
type errorMessageActionType = ReturnType<typeof accountApiActions.archiveAccountFailure>;
type SnackbarMessageConfig = Omit<
  Partial<{
    state: ActionState;
    title: string;
    buttonText: string;
    className: string;
    translationParams: Record<string, string>;
    isClarityMasked: boolean;
  }>,
  'state'
>;
type SnackbarMessage = {
  title: string;
  config?: SnackbarMessageConfig;
};

export const successSnackbarEffect = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(accountApiActions.archiveAccountSuccess),
      tap((action) => {
        const { title, config } = getSuccessMessage(action);
        snackbarService.show(title, config);
      })
    ),
  { functional: true, dispatch: false }
);

export const errorSnackbarEffect = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(accountApiActions.archiveAccountFailure),
      tap((action) => {
        const { title, config } = getErrorMessage(action);
        snackbarService.showError(title, config);
      })
    ),
  { functional: true, dispatch: false }
);

const getSuccessMessage = (action: successMessageActionType): SnackbarMessage => {
  const messageMap = {
    [accountApiActions.archiveAccountSuccess.type]: {
      title: 'LEGAL_ENTITY_DETAILS.ARCHIVE_WORKSPACE.SNACKBAR.SUCCESS',
      config: { translationParams: { workspaceName: action.account.name } },
    },
  };
  return messageMap[action.type];
};

const getErrorMessage = (action: errorMessageActionType): SnackbarMessage => {
  const messageMap = {
    [accountApiActions.archiveAccountFailure.type]: {
      title: 'LEGAL_ENTITY_DETAILS.ARCHIVE_WORKSPACE.SNACKBAR.FAILURE',
    },
  };
  return messageMap[action.type];
};
