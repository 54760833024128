import { Platform } from '../platform.model';
import { CreditDto } from './credit.dto';
import { AvailableCredits, AvailableProduct } from './credit.model';

type ProductCode = CreditDto['product']['type']; // so we don't import with circular dependencies

// TODO TO MOVE AFTER EPIC MERGE
export const SLOT_PREFIX = 'SLOT';

// TODO TO MOVE AFTER EPIC MERGE
export const stripSlotPrefix = (productCode: ProductCode | `SLOT${ProductCode}`): ProductCode =>
  productCode.replace(SLOT_PREFIX, '') as ProductCode;

export const isSlotType = (
  productCode: ProductCode | `SLOT${ProductCode}`
): productCode is `SLOT${ProductCode}` => productCode.startsWith(SLOT_PREFIX);

const aggregation = (acc: AvailableProduct[], current: AvailableProduct) => {
  const found = acc.find((product) => product.type === current.type);
  found ? (found._availableCredits += current._availableCredits) : acc.push(current);
  return acc;
};

export const groupCreditQuantityPerPlatform = (credits: CreditDto[]): AvailableCredits[] =>
  Object.values(Platform)
    .map((platform) => {
      const aggregate = credits
        .filter((credit) => stripSlotPrefix(credit.product.type).startsWith(platform))
        .map(({ product, _availableCredits }) => ({ type: product.type, _availableCredits }))
        .reduce(aggregation, [] as AvailableProduct[]);

      return aggregate.length > 0 ? { platform: platform as Platform, aggregate } : null;
    })
    .filter(Boolean) as AvailableCredits[];

export const isCreditRefundable = (credit: CreditDto): boolean =>
  Array.isArray(credit?._links?.update?.attributes.properties) &&
  credit?._links?.update?.attributes.properties.includes('state');
