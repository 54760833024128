import { Injectable } from '@angular/core';
import { isLanguageCode } from '@mkp/shared/data-access';
import { nullIfEmpty } from '@mkp/shared/util-api';
import { UserDto, UserPatch } from '@mkp/user/data-access';
import { isUserDisplayType, isUserTitle } from '@user/shared/enums';
import { UpdateUser, User, defaultUser } from '@user/shared/models';

@Injectable({ providedIn: 'root' })
export class UserMapper {
  fromJson(userDto: UserDto): User {
    const {
      id,
      email,
      firstName,
      lastName,
      emailVerified,
      profilePictureMediaApiId,
      role,
      phoneNumber,
      _version,
      settings,
      title,
    } = userDto;

    const { displayType, language, marketingConsentAcceptedAt, publicationAiUsageConsent } =
      settings ?? {};

    return {
      id,
      email,
      firstName,
      lastName,
      title: isUserTitle(title) ? title : defaultUser.title,
      emailVerified,
      profilePictureMediaApiId,
      phoneNumber,
      role,
      settings: {
        displayType: isUserDisplayType(displayType)
          ? displayType
          : defaultUser.settings.displayType,
        marketingConsentAcceptedAt: marketingConsentAcceptedAt
          ? new Date(marketingConsentAcceptedAt)
          : null,
        publicationAiUsageConsent: publicationAiUsageConsent ?? null,
        language: isLanguageCode(language) ? language : defaultUser.settings.language,
      },
      _version,
    };
  }

  toJson(user: UpdateUser): UserPatch {
    const { id, phoneNumber, settings, ...userProperties } = user;
    const userDto: UserPatch = userProperties;
    const settingsDto: UserPatch['settings'] = {};

    if (phoneNumber) userDto.phoneNumber = nullIfEmpty(phoneNumber);
    if (settings?.language !== undefined) settingsDto.language = settings.language;
    if (settings?.displayType !== undefined) settingsDto.displayType = settings.displayType;
    if (settings?.marketingConsent !== undefined)
      settingsDto.marketingConsentAcceptedAt = settings.marketingConsent ? 'now' : null;
    if (settings?.publicationAiUsageConsent !== undefined)
      settingsDto.publicationAiUsageConsent = settings.publicationAiUsageConsent;

    if (Object.keys(settingsDto).length) userDto.settings = { ...settingsDto };

    return userDto;
  }
}
