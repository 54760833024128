import { HttpErrorResponse } from '@angular/common/http';
import {
  Application,
  ApplicationCounts,
  ApplicationTab,
  BatchError,
} from '@mkp/application/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationApiActions = createActionGroup({
  source: 'Application Api',
  events: {
    'Route Application Loaded Success': props<{ application: Application }>(),
    'Route Application Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Counts Loaded Success': props<{ applicationCounts: ApplicationCounts }>(),
    'Application Counts Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Applications Loaded Success': props<{ applications: Application[] }>(),
    'Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Success': props<{ application: Application }>(),
    'Application Refresh Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Refresh Not Found': props<{ applicationId: string }>(),
    // this displays the load-more separator
    'More Applications Loaded Success': props<{ applications: Application[] }>(),
    'More Applications Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    // "silently" means that we are not displaying the load-more separator
    'More Applications Silently Loaded Success': props<{ applications: Application[] }>(),
    'More Applications Silently Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Application Deleted Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Application Deleted Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Application Deleted Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Declinations With Email Complete': props<{
      successApplications: Application[];
      errors404: BatchError[];
      errors409: BatchError[];
      errorsOther: BatchError[];
      errorsEmail: BatchError[];
    }>(),
    'Email For Deletion Sent Success': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Email For Deletion Sent Failure': props<{
      errorMessage: HttpErrorResponse;
      applicationFullName: string;
    }>(),
    'Email For Deletion Sent Failure Application Not Found': props<{
      errorMessage: HttpErrorResponse;
      applicationId: string;
    }>(),
    'Statuses Change Completed': props<{
      statusId: string;
      successApplications: Application[];
      errors404: BatchError[];
      errors409: BatchError[];
      errorsOther: BatchError[];
    }>(),
    'Applications Presence Confirmed': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Applications Non Presence Confirmed': emptyProps(),
    'Applications Presence Check Failure': props<{ errorMessage: HttpErrorResponse }>(),
    'Applications Reload For Status Already Changed Success': props<{
      successApplications: Application[];
      reloadedApplications: Application[];
    }>(),
    'Application Reload For Status Already Changed Failure': props<{
      errorMessage: HttpErrorResponse;
    }>(),
  },
});
