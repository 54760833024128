import { CreditDto } from '@mkp/shared/data-access';
import { SlotCode } from '@mkp/shared/util-product';

/**
 * Represents statistics for slot usage
 */
export interface SlotStats {
  used: number;
  available: number;
}

/**
 * Represents a slot option with usage statistics
 */
export interface SlotOption {
  code: SlotCode;
  used: number;
  available: number;
  total: number;
}

export function isSlotCode(value: unknown): value is SlotCode {
  return Object.values(SlotCode).includes(value as SlotCode);
}

/**
 * Determines if a credit is a slot credit
 * @param credit The credit to check
 * @returns True if the credit is a slot credit, false otherwise
 */
export const isCreditSlot = (credit: CreditDto): boolean => {
  const productType = credit.product.type;
  return Object.values(SlotCode).includes(productType as unknown as SlotCode);
};

/**
 * Calculates the used slots for a given credit
 * @param credit The credit to calculate used slots for
 * @returns The number of used slots
 */
export const calculateUsedSlots = (credit: CreditDto): number => {
  if (!isCreditSlot(credit)) return 0;
  return (credit.quantity ?? 0) - (credit._availableCredits ?? 0);
};

/**
 * Calculates the available slots for a given credit
 * @param credit The credit to calculate available slots for
 * @returns The number of available slots
 */
export const calculateAvailableSlots = (credit: CreditDto): number => {
  if (!isCreditSlot(credit)) return 0;
  return credit._availableCredits ?? 0;
};

/**
 * Gets the slot code from a credit
 * @param credit The credit to get the slot code from
 * @returns The slot code or null if the credit is not a slot
 */
export const getSlotCode = (credit: CreditDto): SlotCode | null => {
  if (!isCreditSlot(credit)) return null;
  // @ts-expect-error ProductInformation should be updated to include SlotCode
  return credit.product.type as SlotCode;
};

/**
 * Calculates total slot stats across all slots
 * @param statsMap Map of slot codes to their statistics
 * @returns Combined statistics for all slots
 */
export const calculateTotalSlotStats = (statsMap: Map<SlotCode, SlotStats>): SlotStats => {
  let totalUsed = 0;
  let totalAvailable = 0;

  statsMap.forEach((stats) => {
    totalUsed += stats.used;
    totalAvailable += stats.available;
  });

  return { used: totalUsed, available: totalAvailable };
};

/**
 * Gets slot stats for a specific slot filter (SlotCode or 'All')
 * @param statsMap Map of slot codes to their statistics
 * @param slotFilter The filter to apply ('All' or a specific SlotCode)
 * @returns Statistics for the filtered slots
 */
export const getSlotStatsByFilter = (
  statsMap: Map<SlotCode, SlotStats>,
  slotFilter: SlotCode | 'All' | null
): SlotStats => {
  if (slotFilter === 'All') {
    return calculateTotalSlotStats(statsMap);
  }

  if (slotFilter === null) {
    return { used: 0, available: 0 };
  }

  const stats = statsMap.get(slotFilter);
  return { used: stats?.used || 0, available: stats?.available || 0 };
};

/**
 * Creates a SlotOption object from slot statistics
 * @param code The slot code
 * @param stats The slot statistics
 * @returns A SlotOption object
 */
export const createSlotOption = (code: SlotCode, stats: SlotStats): SlotOption => {
  const total = stats.used + stats.available;
  return { code, used: stats.used, available: stats.available, total };
};
