import { ProductInformation } from '@mkp/shared/util-product';
import { ApiLink } from '../api-link.model';
import { Invoice } from '../invoice/invoice.dto';
import { Revision, RevisionBy, RevisionEmbed } from '../revision.model';
import { UUID } from '../uuid.model';
import { CreditExternalSource } from './credit.model';

export enum CreditState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Refund = 'REFUND',
}

export interface Credit {
  id: UUID;
  externalSource: CreditExternalSource;
  externalId: string | null;
  product: ProductInformation;
  validFrom: string | Date;
  validTo: string | Date;
  state: CreditState | null;
  quantity: number | null;
  accountId: UUID;
  canBeRefund: boolean;
  walletId: UUID | null;
  _links?: Partial<{
    self: ApiLink<'GET'>;
    update: ApiLink<'PATCH'>;
    list: ApiLink<'GET'>;
    createdBy: ApiLink<'GET'>;
    updatedBy: ApiLink<'GET'>;
  }>;
}

interface CreditExtras extends Revision, RevisionEmbed {
  _availableCredits: number;
  _links: Partial<{
    self: ApiLink<'GET'>;
    update: ApiLink<'PATCH'>;
    list: ApiLink<'GET'>;
    createdBy: ApiLink<'GET'>;
    updatedBy: ApiLink<'GET'>;
  }>;
  _type: 'credit';
  _embedded: {
    createdBy: Readonly<RevisionBy>;
    updatedBy: Readonly<RevisionBy | null>;
    invoice?: Invoice;
  };
}

export type CreditDto = Credit & Readonly<CreditExtras>;

export type CreditViewModel = Credit & { canBeRefund: boolean } & Pick<
    CreditDto,
    'id' | '_version'
  > &
  Omit<Credit, 'quantity'>; // ?

export interface TransferCreditInput {
  walletId: string;
  quantity: number;
}

export type CreditCreateInput = Pick<
  Credit,
  | 'externalSource'
  | 'externalId'
  | 'product'
  | 'validFrom'
  | 'validTo'
  | 'quantity'
  | 'accountId'
  | 'walletId'
>;
