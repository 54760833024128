import { Injectable } from '@angular/core';
import { AccountMembershipState } from '@mkp/account-membership/models';
import { AccountMembershipDto, AccountMembershipPatchDto } from './account-membership.dto';
import { AccountMembership, AccountMembershipPatch } from './account-membership.model';

@Injectable({ providedIn: 'root' })
export class AccountMembershipMapper {
  fromJson({
    id,
    userId,
    accountId,
    state,
    _links,
    _version,
    roles,
    _embedded: { account, user },
  }: AccountMembershipDto): AccountMembership {
    return {
      id,
      userId,
      accountId,
      isActive: getIsActive(state),
      canToggleActivation: getCanToggleActivation(_links),
      _version,
      account,
      user,
      roles,
    };
  }

  toJson({ id, _version, isActive }: AccountMembershipPatch): AccountMembershipPatchDto {
    return {
      id,
      state: isActive ? AccountMembershipState.Active : AccountMembershipState.Inactive,
      _version,
    };
  }
}

const getIsActive = (state: string): boolean => state === AccountMembershipState.Active;
const getCanToggleActivation = (_links: AccountMembershipDto['_links']): boolean =>
  !!_links?.update?.attributes?.properties?.some((property) => property === 'state');
