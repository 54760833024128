import { HttpErrorResponse } from '@angular/common/http';
import { inject, WritableSignal } from '@angular/core';
import { selectVacancyIdFromRoute } from '@app/features/vacancy/store/selectors/vacancy.selectors';
import { selectSelectedAccountId } from '@mkp/account/state';
import {
  ApplicationCountsService,
  ApplicationResource,
  ApplicationStatusResource,
  mapApplicationDtoToModel,
} from '@mkp/application/data-access';
import {
  Application,
  ApplicationTab,
  BatchError,
  getStatusIdsForTab,
  isBatchError,
  SuccessAndErrors,
} from '@mkp/application/models';
import {
  applicationApiActions,
  applicationExistGuardActions,
  applicationPageActions,
  applicationStatusApiActions,
  applicationStatusesGuardActions,
} from '@mkp/application/state/actions';
import { documentApiActions } from '@mkp/document/state/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  exhaustMap,
  filter,
  forkJoin,
  from,
  last,
  map,
  Observable,
  of,
  OperatorFunction,
  reduce,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  removeManyActions,
  removeOneActions,
  updateManyActions,
  updateOneActions,
} from './application.reducer';
import { selectApplicationStatusesForSelectedAccount } from './application.selectors';

export const loadRouteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationExistGuardActions.canActivate),
      map(({ id }) => id),
      filter(Boolean),
      exhaustMap((id) =>
        applicationResource.getById(id).pipe(
          map(mapApplicationDtoToModel),
          map((application) =>
            applicationApiActions.routeApplicationLoadedSuccess({ application })
          ),
          catchError((error) =>
            of(applicationApiActions.routeApplicationLoadedFailure({ errorMessage: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const checkApplicationsPresence = createEffect(
  (
    actions$ = inject(Actions),
    applicationResource = inject(ApplicationResource),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(applicationPageActions.opened),
      concatLatestFrom(() => store.select(selectVacancyIdFromRoute)),
      filter(hasVacancyIdInSecondPosition),
      map(([{ tab, offset, limit }, vacancyId]) => ({
        tab,
        offset,
        limit,
        vacancyId,
      })),
      exhaustMap(({ tab, offset, limit, vacancyId }) =>
        applicationResource.checkApplicationsPresence(vacancyId).pipe(
          map((applicationsExist) =>
            applicationsExist
              ? applicationApiActions.applicationsPresenceConfirmed({ tab, offset, limit })
              : applicationApiActions.applicationsNonPresenceConfirmed()
          ),
          catchError((error) => of(applicationApiActions.applicationsPresenceCheckFailure(error)))
        )
      )
    );
  },
  { functional: true }
);

export const fetchCounts = createEffect(
  (
    actions$ = inject(Actions),
    applicationCountsService = inject(ApplicationCountsService),
    store = inject(Store)
  ) =>
    actions$.pipe(
      ofType(
        applicationPageActions.tabChanged,
        applicationApiActions.applicationsPresenceConfirmed,
        ...updateManyActions,
        ...removeOneActions,
        ...removeManyActions,
        ...getUpdateOneActionsForCountsTrigger()
      ),
      concatLatestFrom(() => [
        store.select(selectApplicationStatusesForSelectedAccount),
        store.select(selectVacancyIdFromRoute),
      ]),
      filter(hasVacancyIdInThirdPosition),
      exhaustMap(([, statuses, vacancyId]) =>
        applicationCountsService.fetchApplicationCounts(statuses, vacancyId).pipe(
          map((applicationCounts) =>
            applicationApiActions.applicationCountsLoadedSuccess({ applicationCounts })
          ),
          catchError((error) =>
            of(applicationApiActions.applicationCountsLoadedFailure({ errorMessage: error }))
          )
        )
      )
    ),
  {
    functional: true,
  }
);

export const refreshApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.userSelectApplication,
        applicationPageActions.selectApplicationOnChange
      ),
      map(({ applicationId }) => applicationId),
      filter(Boolean),
      concatMap((applicationId) => {
        return applicationResource.getById(applicationId).pipe(
          map(mapApplicationDtoToModel),
          map((application) => applicationApiActions.applicationRefreshSuccess({ application })),
          catchError((errorMessage) => {
            if (errorMessage.status === 404) {
              return of(applicationApiActions.applicationRefreshNotFound({ applicationId }));
            }
            return of(
              applicationApiActions.applicationRefreshFailure({
                errorMessage,
              })
            );
          })
        );
      })
    );
  },
  { functional: true }
);

export const loadApplicationStatuses = createEffect(
  (
    store = inject(Store),
    actions$ = inject(Actions),
    applicationStatusResource = inject(ApplicationStatusResource)
  ) => {
    return actions$.pipe(
      ofType(applicationStatusesGuardActions.canActivate),
      exhaustMap(() => store.select(selectSelectedAccountId).pipe(filter(Boolean), take(1))),
      concatLatestFrom(() => store.select(selectApplicationStatusesForSelectedAccount)),
      filter(([, applicationStatuses]) => applicationStatuses.length === 0),
      exhaustMap(([selectedAccountId]) =>
        applicationStatusResource.list(selectedAccountId).pipe(
          map((applicationStatuses) =>
            applicationStatusApiActions.applicationStatusesLoadedSuccess({ applicationStatuses })
          ),
          catchError((error) =>
            of(
              applicationStatusApiActions.applicationStatusesLoadedFailure({
                errorMessage: error,
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadApplications = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        applicationPageActions.tabChanged,
        applicationApiActions.applicationsPresenceConfirmed
      ),
      loadMoreApplications(
        applicationApiActions.applicationsLoadedSuccess,
        applicationApiActions.applicationsLoadedFailure
      )
    ),
  { functional: true }
);

export const loadMoreApplicationsWithSeparator = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(applicationPageActions.loadMoreButtonClicked),
      loadMoreApplications(
        applicationApiActions.moreApplicationsLoadedSuccess,
        applicationApiActions.moreApplicationsLoadedFailure
      )
    );
  },
  { functional: true }
);

export const loadMoreApplicationsSilently = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        applicationPageActions.loadMoreApplicationsAfterChange,
        applicationPageActions.loadMoreApplicationsToFindRouteApplication
      ),
      loadMoreApplications(
        applicationApiActions.moreApplicationsSilentlyLoadedSuccess,
        applicationApiActions.moreApplicationsSilentlyLoadedFailure
      )
    );
  },
  { functional: true }
);

// deletion flow
export const deleteApplication = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(documentApiActions.documentsDeletedSuccess),
      exhaustMap(({ applicationId, applicationFullName }) =>
        applicationResource.deleteApplication(applicationId).pipe(
          map(() =>
            applicationApiActions.applicationDeletedSuccess({
              applicationId,
              applicationFullName,
            })
          ),
          catchError((errorMessage) => {
            if (errorMessage.status === 404) {
              return of(
                applicationApiActions.applicationDeletedNotFound({
                  errorMessage,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.applicationDeletedFailure({ errorMessage, applicationFullName })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

// deletion flow
export const sendDeclinedEmailBeforeDeletion = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationPageActions.deleteApplicationWithEmail),
      exhaustMap(({ emailContent, applicationId, applicationFullName }) =>
        applicationResource.sendDeclinationMail(applicationId, emailContent).pipe(
          map(() =>
            applicationApiActions.emailForDeletionSentSuccess({
              applicationFullName,
              applicationId,
            })
          ),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              return of(
                applicationApiActions.emailForDeletionSentFailureApplicationNotFound({
                  errorMessage: err,
                  applicationId,
                })
              );
            }
            return of(
              applicationApiActions.emailForDeletionSentFailure({
                errorMessage: err,
                applicationFullName,
              })
            );
          })
        )
      )
    );
  },
  { functional: true }
);

// status change flow
export const updateApplicationStatuses = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) =>
    actions$.pipe(
      ofType(
        applicationPageActions.userClickedOnBulkActionButtons,
        applicationPageActions.userClickedOnBulkDropdown,
        applicationPageActions.userClickedOnDetailsActionButtons,
        applicationPageActions.userClickedOnDetailsDropdown,
        applicationPageActions.declineApplicationsWithoutEmail
      ),
      exhaustMap((action) => {
        const { updatePayloads, statusId } = action;
        // format of batches: [[obs(app1), obs(app2)], [obs(app3), obs(app4)]
        const batches = applicationResource.getUpdateStatusBatches({
          updatePayloads,
          statusId,
          ...(hasCurrentCount(action) ? { currentCount: action.currentCount } : {}),
        });

        return processBatches(batches).pipe(
          map((applications) => {
            const { successApplications, errors404, errors409, errorsOther } =
              separateSuccessAndErrorsFromBatches(applications);

            return applicationApiActions.statusesChangeCompleted({
              successApplications,
              statusId,
              errors404,
              errors409,
              errorsOther,
            });
          })
        );
      })
    ),
  { functional: true }
);

export const declineApplicationsWithEmail = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(applicationPageActions.declineApplicationsWithEmail),
      exhaustMap(({ emailContent, declineStatusId, updatePayloads, currentCount }) => {
        const batches = applicationResource.getUpdateStatusBatches({
          updatePayloads,
          statusId: declineStatusId,
        });

        const successApplicationsAcc: Application[] = [];
        const errors404Acc: BatchError[] = [];
        const errors409Acc: BatchError[] = [];
        const errorsOtherAcc: BatchError[] = [];
        const errorsEmailAcc: BatchError[] = [];

        return from(batches).pipe(
          concatMap((batch) =>
            // forkJoin for declinations
            forkJoin(batch).pipe(
              concatMap((declineResults) => {
                const { errors404, errors409, errorsOther, successApplications } =
                  separateSuccessAndErrorsFromBatches(declineResults);

                errors404Acc.push(...errors404);
                errors409Acc.push(...errors409);
                errorsOtherAcc.push(...errorsOther);

                // forkJoin for emails
                return forkJoin(
                  applicationResource.getDeclinationMailBatch({
                    applications: successApplications,
                    emailContent,
                    currentCount,
                  })
                ).pipe(
                  tap((emailResults: (void | BatchError)[]) => {
                    const emailErrors = emailResults.filter(isBatchError);
                    const errorIds = emailErrors.map(({ applicationId }) => applicationId);
                    const emailSuccessApplications = successApplications.filter(
                      ({ id }) => !errorIds.includes(id)
                    );
                    errorsEmailAcc.push(...emailErrors);
                    successApplicationsAcc.push(...emailSuccessApplications);
                  }),
                  defaultIfEmpty(undefined)
                );
              })
            )
          ),
          last(),
          map(() =>
            applicationApiActions.declinationsWithEmailComplete({
              successApplications: successApplicationsAcc,
              errors404: errors404Acc,
              errors409: errors409Acc,
              errorsOther: errorsOtherAcc,
              errorsEmail: errorsEmailAcc,
            })
          )
        );
      })
    );
  },
  { functional: true }
);

export const reloadApplicationForStatusAlreadyChanged = createEffect(
  (actions$ = inject(Actions), applicationResource = inject(ApplicationResource)) => {
    return actions$.pipe(
      ofType(
        applicationApiActions.statusesChangeCompleted,
        applicationApiActions.declinationsWithEmailComplete
      ),
      filter(({ errors409 }) => errors409.length > 0),
      exhaustMap(({ successApplications, errors409 }) =>
        applicationResource.getByIds(errors409.map(({ applicationId }) => applicationId)).pipe(
          map((reloadedApplications) =>
            applicationApiActions.applicationsReloadForStatusAlreadyChangedSuccess({
              successApplications,
              reloadedApplications,
            })
          ),
          catchError((error) =>
            of(
              applicationApiActions.applicationReloadForStatusAlreadyChangedFailure({
                errorMessage: error,
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

type LoadOneOrMoreApplicationsSuccessActionCreator =
  | typeof applicationApiActions.applicationsLoadedSuccess
  | typeof applicationApiActions.moreApplicationsLoadedSuccess
  | typeof applicationApiActions.moreApplicationsSilentlyLoadedSuccess;
type LoadOneOrMoreApplicationsFailureActionCreator =
  | typeof applicationApiActions.applicationsLoadedFailure
  | typeof applicationApiActions.moreApplicationsLoadedFailure
  | typeof applicationApiActions.moreApplicationsSilentlyLoadedFailure;
type LoadOneOrMoreApplicationsAction = ReturnType<
  LoadOneOrMoreApplicationsSuccessActionCreator | LoadOneOrMoreApplicationsFailureActionCreator
>;

const loadMoreApplications = (
  successAction: LoadOneOrMoreApplicationsSuccessActionCreator,
  failureAction: LoadOneOrMoreApplicationsFailureActionCreator,
  store = inject(Store),
  applicationResource = inject(ApplicationResource)
): OperatorFunction<
  {
    tab: ApplicationTab;
    limit: number;
    offset: number;
  },
  LoadOneOrMoreApplicationsAction
> => {
  return (source) =>
    source.pipe(
      concatLatestFrom(() => [
        store.select(selectApplicationStatusesForSelectedAccount),
        store.select(selectVacancyIdFromRoute),
      ]),
      filter(hasVacancyIdInThirdPosition),
      map(([{ tab, limit, offset }, statuses, vacancyId]) => ({
        statusIds: getStatusIdsForTab(tab, statuses),
        vacancyId,
        offset,
        limit,
        tab,
      })),
      switchMap(({ statusIds, vacancyId, offset, limit, tab }) =>
        applicationResource
          .fetchApplicationsByTab({
            statusIds,
            vacancyId,
            offset,
            limit,
            tab,
          })
          .pipe(
            map((applications) => successAction({ applications })),
            catchError((error) => of(failureAction({ errorMessage: error })))
          )
      )
    );
};

const hasVacancyIdInThirdPosition = <T, V>(
  input: [T, V, string | undefined]
): input is [T, V, string] => input[2] !== undefined;
const hasVacancyIdInSecondPosition = <T>(input: [T, string | undefined]): input is [T, string] =>
  input[1] !== undefined;

// we don't want to refresh the count on every refresh success: only "changed" and "notfound" (in removeOnActions)
const getUpdateOneActionsForCountsTrigger = () =>
  updateOneActions.map((action) =>
    action.type === applicationApiActions.applicationRefreshSuccess.type
      ? applicationPageActions.applicationRefreshStatusChanged
      : action
  );

const processBatches = <T>(batches: Observable<T>[][]): Observable<T[]> =>
  // api calls within a batch should be called in parallel: forkjoin
  // returns this format: [obs([app1, app2]), obs([app3, app4])]
  from(batches.map((batch) => forkJoin(batch))).pipe(
    // all batches should be requested sequentially: concatMap
    concatMap((batchedRequest) => batchedRequest),
    // format here: obs([app1, app2, app3, app4])
    reduce((acc, batchResult) => [...acc, ...batchResult], [] as T[])
  );

const isApplication = (application: Application | BatchError): application is Application =>
  (application as Application).id !== undefined;

const separateSuccessAndErrorsFromBatches = (
  applications: (Application | BatchError)[]
): SuccessAndErrors =>
  applications.reduce<SuccessAndErrors>(
    (acc, application) => ({
      successApplications: isApplication(application)
        ? [...acc.successApplications, application]
        : acc.successApplications,
      errors404:
        isBatchError(application) && application.error.status === 404
          ? [...acc.errors404, application]
          : acc.errors404,
      errors409:
        isBatchError(application) && application.error.status === 409
          ? [...acc.errors409, application]
          : acc.errors409,
      errorsOther:
        isBatchError(application) && ![404, 409].includes(application.error.status)
          ? [...acc.errorsOther, application]
          : acc.errorsOther,
    }),
    { successApplications: [], errors404: [], errors409: [], errorsOther: [] }
  );

const hasCurrentCount = <T>(action: T): action is T & { currentCount: WritableSignal<number> } =>
  (action as { currentCount: WritableSignal<number> }).currentCount !== undefined;
