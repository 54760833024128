import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { selectIsUserRolesEnabled } from '@mkp/debug/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const canLoadNewUserManagement = (): Observable<true | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store
    .select(selectIsUserRolesEnabled)
    .pipe(
      map(
        (isUserRoleEnabled) =>
          isUserRoleEnabled || router.createUrlTree([AppConfig.routes.dashboard])
      )
    );
};
