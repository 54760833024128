import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { selectRouteUrl } from '@app/store/selectors';
import { AppConfig } from '@config/app.config';
import { SplashscreenService } from '@core/services';
import { selectSelectedAccount } from '@mkp/account/state';
import { authActions, authApiActions, authWorkspaceActions } from '@mkp/auth/actions';
import { AccountProtectionService, AuthFacade, Token } from '@mkp/auth/util';
import { verifyIdentityPendingParams } from '@mkp/onboarding/feature-verify-identity';
import { ZendeskService } from '@mkp/zendesk/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { selectIsBoUser, selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { combineLatest, distinctUntilChanged, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

export const appBootstrapped = createEffect(
  (
    actions$ = inject(Actions),
    authFacade = inject(AuthFacade),
    store = inject(Store),
    splashscreen = inject(SplashscreenService)
  ) =>
    actions$.pipe(
      ofType(authActions.appBootstrapped),
      switchMap(() =>
        authFacade.isAuthenticated$.pipe(
          switchMap((logged: boolean) => (logged ? of(logged) : authFacade.fetchAccessToken())),
          filter((logged: boolean | Token) => !!logged),
          switchMap(() => authFacade.isVerified$),
          concatLatestFrom(() => store.select(selectLoggedInUser).pipe(filter(Boolean))),
          map(([isVerified, user]) =>
            isVerified
              ? authActions.userIsVerified({ userId: user.id })
              : authActions.userIdentityNotVerified()
          ),
          catchError((error) => {
            splashscreen.hide();
            return of(authActions.appBootstrapFailed({ error }));
          })
        )
      )
    ),
  { functional: true }
);

export const userIdentityNotVerified = createEffect(
  (
    actions$ = inject(Actions),
    router = inject(Router),
    splashscreen = inject(SplashscreenService)
  ) =>
    actions$.pipe(
      ofType(authActions.userIdentityNotVerified),
      tap(() => {
        const navigated = router.navigate([AppConfig.routes.verifyIdentity], {
          queryParams: verifyIdentityPendingParams,
        });
        from(navigated).subscribe(() => splashscreen.hide());
      })
    ),
  { functional: true, dispatch: false }
);

export const userHasNoActiveMemberships = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length === 0),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([_, isBoUser]) => isBoUser !== null && !isBoUser),
      tap(() => router.navigate([AppConfig.routes.claimCompany])),
      map(() => authWorkspaceActions.workspaceResourceNotLoading({ accountCount: 0 }))
    ),
  { functional: true }
);

// cf JMP-4357
// when we fail to download the initial account we want to logout the user and display an error
// auth0 refreshes the page after a logout, so we need to set a flag in session to communicate that there was an error
export const forceLogoutWhenFailingToLoadInitialAccount = createEffect(
  (actions$ = inject(Actions), authFacade = inject(AuthFacade)) =>
    actions$.pipe(
      ofType(
        authActions.loadActiveAccountMembershipsFailed,
        authActions.loadAccountsAfterLoginFailed,
        authActions.loadCompaniesAfterLoginFailed
      ),
      tap(() => sessionStorage.setItem('forcedLogout', 'true')),
      tap(() => authFacade.logout())
    ),
  { functional: true, dispatch: false }
);

export const updateZendeskToken = createEffect(
  (actions$ = inject(Actions), zendeskService = inject(ZendeskService)) =>
    actions$.pipe(
      ofType(authApiActions.fetchAccessTokenSuccess),
      tap(({ zendeskJWT }) => zendeskService.login(zendeskJWT))
    ),
  { functional: true, dispatch: false }
);

export const redirectBoUserFromRestrictedRoutes = createEffect(
  (store = inject(Store), accountProtectionService = inject(AccountProtectionService)) =>
    combineLatest({
      // effect triggers -> check only the initial route and then every the account change
      firstUrl: store.select(selectRouteUrl).pipe(filter(Boolean), distinctUntilChanged(), take(1)),
      selectedAccount: store
        .select(selectSelectedAccount)
        .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)),
    }).pipe(
      concatLatestFrom(() => [store.select(selectIsBoUser), store.select(selectRouteUrl)]),
      filter(([_, isBoUser]) => isBoUser !== null && isBoUser),
      tap(([, _, currentUrl]) => accountProtectionService.checkCurrentUrl(currentUrl))
    ),
  { functional: true, dispatch: false }
);
