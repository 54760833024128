<div #tabHeaderWrapper class="tab-header-wrapper" (scroll)="onScroll()">
  @for (tab of tabs; track tab.title; let i = $index) {
    <div
      #tabItem
      class="tab-item"
      [class]="classes"
      [class.tab-disabled]="disabled"
      [class.active]="tab.active"
      [mkpDataTest]="tab.dataTest"
      [attr.data-title]="tab.title"
      (click)="!disabled && selectTab(tab, i)"
    >
      <!-- save the content in a ng-template to avoid code duplication-->
      <ng-template #content>
        @if (tab.icon) {
          <ui-asset [name]="tab.icon" />
        }
        {{ tab.title | translate }}
        @if (tab.count !== undefined) {
          @if (tab.countLoaded) {
            <ui-badge [config]="badgeConfig" [class.active]="tab.active">
              {{ tab.count }}{{ tab.totalCount !== undefined ? ' / ' + tab.totalCount : '' }}
            </ui-badge>
          } @else {
            <mkp-skeleton-loader appearance="circle" [theme]="{ width: '24px', height: '24px' }" />
          }
        }
      </ng-template>
      <!-- this is the div that is actually being displayed -->
      <div class="content">
        <ng-container *ngTemplateOutlet="content" />
      </div>
      <!-- when selecting a tab it takes more space because of the font-weight -->
      <!-- this div only exists to take the lateral space of the active state of the element and avoid flickering -->
      <div class="active-width-spacer">
        <ng-container *ngTemplateOutlet="content" />
      </div>
    </div>
  }
  <tab-ink-bar />
</div>
