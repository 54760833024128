import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as accountBoEffects from './account-bo.effects';
import * as accountEffects from './account.effects';
import * as accountSnackbarEffects from './account-snackbar.effects';
import { accountFeature } from './account.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(accountFeature),
    EffectsModule.forFeature([accountEffects, accountBoEffects, accountSnackbarEffects]),
  ],
})
export class AccountStateModule {}
