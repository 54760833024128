export type ButtonType = 'button' | 'submit' | 'reset';

export enum ButtonSize {
  Medium = 'medium',
  Small = 'small',
  Smaller = 'smaller',
}

export enum ButtonColor {
  Transparent = 'transparent',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  DestructivePrimary = 'destructive-primary',
  DestructiveSecondary = 'destructive-secondary',
  DestructiveTertiary = 'destructive-tertiary',
  DestructiveLink = 'destructive-link',
  Link = 'link',
  Basic = 'basic',
  SecondaryTurquoise = 'secondary-turquoise',
  SecondaryBlue = 'secondary-blue',
  SecondaryGray = 'secondary-gray',
  SecondaryGrayLight = 'secondary-gray-light',
}

export enum ButtonVariant {
  Circle = 'circle',
  Square = 'square',
}

export enum ActionState {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Purple = 'purple',
  Turquoise = 'turquoise',
}

export interface ButtonBase {
  size: ButtonSize;
  color: ButtonColor;
  variant: ButtonVariant;
}

export interface ButtonExtras {
  classList: string[];
  disableRipple: boolean;
  floatable: boolean;
  elevation: number;
  fullWidth: boolean;
  mobileFullWidth: boolean;
}

export interface ButtonWrapper {
  config: Button;
  disabled?: boolean;
  state?: ActionState;
  content: Partial<{
    label: string;
    url: string;
    queryParams?: Record<string, string>;
    iconName: string;
    dataTest: string;
  }>;
}

export type Button = Partial<ButtonBase & ButtonExtras>;
