import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { TabConfig, TabPadding, TabSize } from './tab.config';

@Component({
  selector: 'mds-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TabHeaderComponent],
})
export class TabsComponent implements AfterContentInit {
  @Input({ required: true }) config!: TabConfig;
  @Output() handleClick = new EventEmitter<number | string>();
  @ContentChildren(TabItemComponent) tabs!: QueryList<TabItemComponent>;

  headerPadding: TabPadding | undefined = undefined;
  @HostBinding('class') get headerPaddingClass(): string {
    return this.headerPadding ? `header-padding-${this.headerPadding}` : '';
  }

  TabSize = TabSize;

  ngAfterContentInit() {
    this.config.showHeader ??= true;
    this.headerPadding = this.config?.headerPadding;

    // FIXME: mds-tab emits "handleClick" at initialization, to me it is a weird behaviour, because nobody actually clicked
    // In the meantime I added the preventFirstClick config to avoid that, but I think we should rework
    if (!this.config.preventFirstClick) {
      const activeTab = this.tabs.find((tab) => tab.active);

      if (this.tabs?.length) {
        const index = activeTab?.index || 0;
        const action = this.tabs.get(index)?.action || '';

        this.selectTab({ index, action });
      }
    }
  }

  selectTab({ index, action }: { index: number; action: string }) {
    this.tabs.toArray().forEach((tab, tabIndex) => (tab.active = tabIndex === index));

    this.handleClick.emit(action || index);
  }
}
