import { WritableSignal } from '@angular/core';
import {
  ApplicationStep,
  ApplicationTab,
  ApplicationUpdatePayload,
  EmailContent,
} from '@mkp/application/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationPageActions = createActionGroup({
  source: 'Applications Page',
  events: {
    Opened: props<{ tab: ApplicationTab; limit: number; offset: 0 }>(),
    'Load More Button Clicked': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Load More Applications After Change': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Load More Applications To Find Route Application': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Decline Applications With Email': props<{
      updatePayloads: ApplicationUpdatePayload[];
      declineStatusId: string;
      emailContent: EmailContent;
      currentCount: WritableSignal<number> | undefined;
    }>(),
    'Decline Applications Without Email': props<{
      updatePayloads: ApplicationUpdatePayload[];
      statusId: string;
      newStep: ApplicationStep;
      currentCount: WritableSignal<number> | undefined;
    }>(),
    'Delete Application With Email': props<{
      applicationId: string;
      applicationFullName: string;
      emailContent: EmailContent;
    }>(),
    'Delete Application Without Email': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Tab Changed': props<{ tab: ApplicationTab; limit: number; offset: 0 }>(),
    'User Clicked On Details Dropdown': props<{
      updatePayloads: ApplicationUpdatePayload[];
      statusId: string;
      newStep: ApplicationStep;
    }>(),
    'User Clicked On Details Action Buttons': props<{
      updatePayloads: ApplicationUpdatePayload[];
      statusId: string;
      newStep: ApplicationStep;
    }>(),
    'User Clicked On Bulk Dropdown': props<{
      updatePayloads: ApplicationUpdatePayload[];
      statusId: string;
      newStep: ApplicationStep;
      currentCount: WritableSignal<number>;
    }>(),
    'User Clicked On Bulk Action Buttons': props<{
      updatePayloads: ApplicationUpdatePayload[];
      statusId: string;
      newStep: ApplicationStep;
      currentCount: WritableSignal<number>;
    }>(),
    'View Document': props<{
      pdfUrl: string;
      documentId: string;
      applicationId: string;
      originalUrl: string;
    }>(),
    'User Select Application': props<{ applicationId: string }>(),
    'Select Application On Change': props<{
      applicationId: string | undefined;
      changedApplicationWasLast: boolean;
    }>(),
    'Select First Application': props<{ applicationId: string | undefined }>(),
    'Select Route Application': props<{ applicationId: string }>(),
    'Download Document': props<{
      downloadUrl: string;
      documentId: string;
      applicationId: string;
      documentName: string;
    }>(),
    'Application Refresh Status Changed': emptyProps(),
  },
});
