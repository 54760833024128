import { Account } from '@mkp/account/data-access';
import { Revision } from '../revision.model';
import { WalletState, WalletAccessRole } from './wallet.model';

export interface WalletAccessDto<T = WalletAccessRole> {
  accountId: Account['id']; // UUID
  role: T;
}

export interface WalletDto extends Revision {
  readonly id: string; // UUID
  name: string;
  state: WalletState;
  walletAccesses: [
    WalletAccessDto<WalletAccessRole.Owner>,
    ...WalletAccessDto<WalletAccessRole.User>[],
  ];
}

export type WalletPostBody = Pick<WalletDto, 'name' | 'walletAccesses'>;
export type UpdateWalletDto = Partial<WalletPostBody>;
export const DEFAULT_WALLET_NAME = 'Default';
