export enum SlotCode {
  JobsBasic = 'SLOTJOBSBASIC',
  JobsAdvanced = 'SLOTJOBSADVANCED',
  JobsUltimate = 'SLOTJOBSULTIMATE',
  JobsUltimatePlus = 'SLOTJOBSULTIMATEPLUS',
  JobupBasic = 'SLOTJOBUPBASIC',
  JobupAdvanced = 'SLOTJOBUPADVANCED',
  JobupUltimate = 'SLOTJOBUPULTIMATE',
  JobupUltimatePlus = 'SLOTJOBUPULTIMATEPLUS',
}
