import { LayoutInputParams, TopHeader } from '@mkp/layout/state';
import {
  cmsTopHeader,
  landingPageTopHeader,
  loggedInUserDefaultTopHeader,
  regFlowTopHeader,
  summaryTopHeader,
  supportCenterTopHeader,
  withBackButtonTopHeader,
  workspaceSelectTopHeader,
} from './layout-header.config';
import {
  APPLICATIONS,
  APPLICATIONS_WITH_QUERY,
  BO_CLAIM_COMPANY,
  BOOST_VACANCY,
  CLAIM_COMPANY,
  CLAIM_MANAGEMENT_DETAILS_WITH_ID,
  CMS_PAGE,
  CREATE_SUMMARY,
  CREATE_VACANCY,
  EDIT_VACANCY,
  LANDING_PAGE,
  MY_USER_PROFILE,
  PREVIEW_VACANCY,
  PUBLISH_VACANCY,
  SELECT_WORKSPACE,
  STORE_COMPARISON_TABLE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  SUPPORT_CENTER,
  urlMatchesAnyPage,
  VERIFY_IDENTITY,
  removeQueryParams,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_COMPARISON,
  PURCHASE_HISTORY,
} from './layout-pages.helpers';

export const getPageHeader = ({ url, isMobile, user }: LayoutInputParams) => {
  const sanitizedUrl = removeQueryParams(url);
  return user
    ? getLoggedInUserHeader(sanitizedUrl, isMobile)
    : getVisitorUserHeader(sanitizedUrl, isMobile);
};

export const getLoggedInUserHeader = (url: string, isMobile: boolean): TopHeader => {
  switch (true) {
    case url.includes(SELECT_WORKSPACE):
      return workspaceSelectTopHeader(isMobile);
    case url.includes(SUPPORT_CENTER):
      return supportCenterTopHeader(true);
    case url.includes(CMS_PAGE):
      return cmsTopHeader(true);
    case url.includes(CREATE_SUMMARY) && !url.includes(SUMMARY_ORDER_COMPLETE):
      return summaryTopHeader(isMobile);
    case urlMatchesAnyPage(PAGES_WITH_BACK_BUTTON, url):
      return withBackButtonTopHeader(isMobile);
    case urlMatchesAnyPage([VERIFY_IDENTITY, CLAIM_COMPANY], url, [BO_CLAIM_COMPANY]):
      return regFlowTopHeader(isMobile);
    default:
      return loggedInUserDefaultTopHeader(isMobile);
  }
};

export const getVisitorUserHeader = (url: string, isMobile: boolean): TopHeader => {
  switch (true) {
    case urlMatchesAnyPage([LANDING_PAGE, SUPPORT_CENTER], url):
      return landingPageTopHeader(false);
    case url.includes(CMS_PAGE):
      return cmsTopHeader(false);
    default:
      return regFlowTopHeader(isMobile);
  }
};

const PAGES_WITH_BACK_BUTTON = [
  CREATE_VACANCY,
  EDIT_VACANCY,
  PREVIEW_VACANCY,
  PUBLISH_VACANCY,
  BOOST_VACANCY,
  APPLICATIONS,
  APPLICATIONS_WITH_QUERY,
  MY_USER_PROFILE,
  STORE_SUMMARY,
  SUMMARY_ORDER_COMPLETE,
  STORE_COMPARISON_TABLE,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_COMPARISON,
  CLAIM_MANAGEMENT_DETAILS_WITH_ID,
  PURCHASE_HISTORY,
];
