import { inject, Injectable } from '@angular/core';
import { FREE_PRODUCT_CODES, isFreeProductCode, ProductCode } from '@mkp/shared/util-product';
import { formatISO9075 } from 'date-fns';
import { exhaustMap, map, Observable, of, switchMap } from 'rxjs';
import { BaseHttpResource } from '../base-http.resource';
import { CreditRedemptionDto } from '../credit-redemption/credit-redemption.dto';
import { CreditRedemptionState } from '../credit-redemption/credit-redemption.model';
import { CreditRedemptionResource } from '../credit-redemption/credit-redemption.resource';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';
import { UUID } from '../uuid.model';
import {
  Credit,
  CreditDto,
  CreditState,
  TransferCreditInput,
  CreditCreateInput,
} from './credit.dto';
import { QueryHeaders, QueryParams } from '../query-params.model';

type PreviousCreditRedemptionData = Pick<CreditRedemptionDto, 'id' | '_version' | '_state'> & {
  productType: ProductCode;
};

const getExcludedProductFilter = (excludedProducts: ProductCode[]): string =>
  excludedProducts.map((product) => `product.type!=${product}`).join(';');

@Injectable({ providedIn: 'root' })
export class CreditResource extends BaseHttpResource<
  CreditDto,
  CoreListEnvelop<CreditDto>,
  CreditCreateInput
> {
  private readonly creditRedemptionResource = inject(CreditRedemptionResource);

  constructor() {
    super('credit');
  }

  /**
   * Get **ALL** credits; This is an override of the `getWithQuery` method ;
   * Be careful when using this method, it might return a large number of results
   * Returning all available and past credits (use filters to get specific results)
   * @override getWithQuery
   * @param params
   * @param headers
   */
  override getWithQuery(
    params: Partial<QueryParams>,
    headers: QueryHeaders = {}
  ): Observable<CoreListEnvelop<CreditDto>> {
    params.filter = `${params.filter ? `${params.filter};` : ''}${getExcludedProductFilter(FREE_PRODUCT_CODES)}`;
    return super.getWithQuery(params, headers);
  }

  useCredit(
    accountId: string | UUID,
    vacancyId: string | UUID,
    product: ProductCode,
    { id, _version, _state, productType }: Partial<PreviousCreditRedemptionData>
  ): Observable<unknown> {
    const start = formatISO9075(new Date());
    const filter = `wallet.walletAccesses.account.id==${accountId};product.type==${product};_availableCredits>0;validTo>${start}`;
    const sort = `createdAt=asc`; // FIFO

    let prior = of({});

    if (id && _version && productType && _state === CreditRedemptionState.Active) {
      if (isFreeProductCode(productType)) {
        prior = this.creditRedemptionResource.update(id, { id, _version, stoppedAt: 'now' });
      } else {
        // we probably don't need this because upgrading a paid product is now handled via customer service (opens zendesk chat)
        prior = this.update(id, { id, _version, state: CreditState.Refund });
      }
    }

    return prior.pipe(
      switchMap(() =>
        super.getWithQuery({ limit: 1, filter, sort }).pipe(
          map((response) => response._embedded.results[0]),
          exhaustMap(({ id }) =>
            this.http.post(`${this.url}/${id}/redeem`, { vacancyId, start: 'now' })
          )
        )
      )
    );
  }

  transferCredit(
    creditId: Credit['id'],
    { walletId, quantity }: TransferCreditInput
  ): Observable<unknown> {
    return this.http.post(`${this.url}/${creditId}/transfer`, {
      walletId,
      quantity,
    });
  }

  /**
   * @description Redeems a slot (special kind of credit) for a specific vacancy
   * @param slotId The ID of the slot to redeem
   * @param vacancyId The ID of the vacancy to publish
   * @param startDate Optional start date, defaults to 'now'
   * @returns Observable of the API response
   */
  redeemSlot(slotId: string, vacancyId: string, startDate = 'now'): Observable<unknown> {
    return this.http.post(`${this.url}/${slotId}/redeem`, {
      vacancyId,
      start: startDate,
    });
  }
}
