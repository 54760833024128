import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';

const NON_ESSENTIAL_APIS = [environment.api.ats];

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  handleError(errorResponse: HttpErrorResponse): Observable<never> {
    switch (errorResponse.status) {
      case 401: {
        // not sure why ecom should redirect to 401 and others to home, is it a mistake ?
        const redirectPath = isEcomHealthCheck(errorResponse.url)
          ? AppConfig.routes.error401
          : AppConfig.routes.home;

        this.router.navigateByUrl(redirectPath);
        break;
      }
      case 500: {
        if (isEssentialApi(errorResponse.url)) {
          this.router.navigateByUrl(AppConfig.routes.error500);
        }
        break;
      }
      case 400:
      case 304:
      case 404:
      case 403:
      case 422:
      case 409:
      default:
    }

    // Always return the error
    return throwError(() => errorResponse);
  }
}

const isEssentialApi = (url: string | null): boolean =>
  !NON_ESSENTIAL_APIS.some((apiUrl) => !!url?.includes(apiUrl));
const isEcomHealthCheck = (url: string | null): boolean => !!url?.includes('/shop-api/me');
