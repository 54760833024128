import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpResource } from '../base-http.resource';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';
import { WalletPostBody, WalletDto } from './wallet.dto';
import { generateWorkspacesUpdateBody } from './wallet.helper';
import { WalletState } from './wallet.model';

export interface AddWorkspacesInput {
  wallet: WalletDto;
  currentAccount: string;
  selectedAccounts: string[];
}

@Injectable({ providedIn: 'root' })
export class WalletResource extends BaseHttpResource<
  WalletDto,
  CoreListEnvelop<WalletDto>,
  WalletPostBody
> {
  constructor() {
    super('wallet');
  }

  updateWorkspaces({
    wallet,
    currentAccount,
    selectedAccounts,
  }: AddWorkspacesInput): Observable<unknown> {
    return super.update(
      wallet.id,
      generateWorkspacesUpdateBody({ wallet, currentAccount, selectedAccounts })
    );
  }

  archive(wallet: WalletDto): Observable<unknown> {
    return super.update(wallet.id, {
      state: WalletState.Archived,
      _version: wallet._version,
    });
  }
}
