import {
  JOBS_PRODUCT_CODES,
  JOBUP_PRODUCT_CODES,
  JS24_PRODUCT_CODES,
} from './product-offering.model';
import { Product, ProductCode } from './product.model';

export enum ProductOfferingBenefit {
  Calendar = 'calendar', // 31 days online
  Calendar7Jobs = 'calendar-7-jobs', // 7 days online
  Calendar7Jobup = 'calendar-7-jobup', // 7 days online
  Calendar7Plus = 'calendar-7-plus', // 7 days online , 24 days mobile app
  Calendar30 = 'calendar-30',
  Calendar31Limited = 'calendar-31-limited', // 31 days online with limited visibility
  Calendar45 = 'calendar-45', // 45 days online
  Calendar60 = 'calendar-60', // 60 days online
  GoogleJobPosting = 'google-job-posting', // Visible in Google search
  InboxIn = 'inbox-in', // Sent to job seekers by e-mail
  JobAlert = 'job-alert', // Job Alert
  JobAlert1x = 'job-alert/1x', // Job Alert - 1x
  JobAlert3x = 'job-alert/3x', // Job Alert - 3x
  JobAlert5x = 'job-alert/5x', // Job Alert - 5x
  JobAlert9x = 'job-alert/9x', // JOb Alert - 9x
  JobAlert11x = 'job-alert/11x', // Job Alert - 11x
  JobBoost = 'job-boost', // Job Boost
  JobBoost2x = 'job-boost/2x', // Job Boost - 2x
  JobBoost4x = 'job-boost/4x', // Job Boost - 4x
  JobBoost8x = 'job-boost/8x', // Job Boost - 8x
  JobBoost10x = 'job-boost/10x', // Job Boost - 10x
  OnlineATS = 'online-ats', // Online ATS
  OnlineCampaigns = 'online-campaigns', // Online Campaigns
  OnlineCampaignsCheckmark = 'online-campaigns/checkmark', // Online Campaigns - Checkmark
  OnlineCampaignsBoost = 'online-campaigns/boost', // Online Campaigns - Boost
  OnlineCampaignsPlus = 'online-campaigns/plus', // Online Campaigns – Plus
  Performance = 'performance-plus', // Performance Plus
  PartnerNetwork = 'partner-network', // Partner Network
  PartnerNetworkCheckmark = 'partner-network/checkmark', // Partner Network - Checkmark
  PartnerNetworkBoost = 'partner-network/boost', // Partner Network - Boost
  PartnerNetworkPlus = 'partner-network/plus', // Partner Network - Plus
}

export enum ProductPlatformGroup {
  Jobs = 'jobs',
  JobScout24 = 'job-scout-24',
  JobUp = 'jobup',
}

export enum ProductPlatformOther {
  JobWinner = 'job-winner',
  TopJobsAlpha = 'top-jobs-alpha',
}

export type PlatformLogo = 'jobs_logo' | 'jobscout24' | 'jobup_logo';

export type ProductOfferingPlatform = ProductPlatformGroup | ProductPlatformOther;

export const PRODUCT_OFFERING_PLATFORMS = [
  ...Object.values(ProductPlatformGroup),
  ...Object.values(ProductPlatformOther),
] as ProductOfferingPlatform[];

export const PRODUCT_CALENDAR_PERIOD_MAP = {
  [ProductOfferingBenefit.Calendar]: 31,
  [ProductOfferingBenefit.Calendar31Limited]: 31,
  [ProductOfferingBenefit.Calendar7Jobs]: 7,
  [ProductOfferingBenefit.Calendar7Jobup]: 7,
  [ProductOfferingBenefit.Calendar7Plus]: 7,
  [ProductOfferingBenefit.Calendar45]: 45,
  [ProductOfferingBenefit.Calendar60]: 60,
  [ProductOfferingBenefit.Calendar30]: 30,
} as const;

export type CalendarFeature = keyof typeof PRODUCT_CALENDAR_PERIOD_MAP;

export interface ProductOfferingUpgrade {
  canUpgrade: boolean;
  productCode?: Product['code'];
}

export type ProductOfferingFeature = ProductOfferingPlatform | ProductOfferingBenefit;

// maps groups of products and defines sorting order
export const PRODUCT_OFFERING_GROUP_MAP: Record<ProductPlatformGroup, ProductCode[]> = {
  [ProductPlatformGroup.JobScout24]: JS24_PRODUCT_CODES,
  [ProductPlatformGroup.Jobs]: JOBS_PRODUCT_CODES,
  [ProductPlatformGroup.JobUp]: JOBUP_PRODUCT_CODES,
};

// reverse the PRODUCT_OFFERING_GROUP_MAP
// maps ProductCode -> ProductPlatformGroup
const PRODUCT_CODE_PLATFORM_GROUP_MAP: Record<ProductCode, ProductPlatformGroup> = Object.entries(
  PRODUCT_OFFERING_GROUP_MAP
).reduce(
  (acc, [platformGroup, productCodes]) => ({
    ...acc,
    ...productCodes.reduce((acc, productCode) => ({ ...acc, [productCode]: platformGroup }), {}),
  }),
  {} as Record<ProductCode, ProductPlatformGroup>
);

const getLogoFromPlatformGroup = (platformGroup: ProductPlatformGroup): PlatformLogo => {
  switch (platformGroup) {
    case ProductPlatformGroup.JobScout24:
      return 'jobscout24';
    case ProductPlatformGroup.JobUp:
      return 'jobup_logo';
    case ProductPlatformGroup.Jobs:
    default:
      return 'jobs_logo';
  }
};

// reuse the PRODUCT_CODE_PLATFORM_GROUP_MAP to generate a PRODUCT_CODE_LOGO_MAP
export const PRODUCT_CODE_LOGO_MAP: Record<ProductCode, PlatformLogo> = Object.entries(
  PRODUCT_CODE_PLATFORM_GROUP_MAP
).reduce(
  (acc, [productCode, platformGroup]) => ({
    ...acc,
    [productCode]: getLogoFromPlatformGroup(platformGroup),
  }),
  {} as Record<ProductCode, PlatformLogo>
);

export const getLogoFromProductCode = (productCode: ProductCode): PlatformLogo =>
  PRODUCT_CODE_LOGO_MAP[productCode];

export const PRODUCT_OFFERING_ULTIMATE_PRODUCTS = [
  ProductCode.JOBSOFFER22L,
  ProductCode.JOBSOFFER22XL,
];

export const getPlatformGroupFromProductCode = (productCode: ProductCode): ProductPlatformGroup =>
  PRODUCT_CODE_PLATFORM_GROUP_MAP[productCode];

export const PRODUCT_PLATFORM_GROUP_NAME_MAP: Record<ProductPlatformGroup, string> = {
  [ProductPlatformGroup.Jobs]: 'PRODUCT_PLATFORM_GROUP.JOBS',
  [ProductPlatformGroup.JobUp]: 'PRODUCT_PLATFORM_GROUP.JOBUP',
  [ProductPlatformGroup.JobScout24]: 'PRODUCT_PLATFORM_GROUP.JOB_SCOUT_24',
};
