import { Injectable } from '@angular/core';
import {
  BaseHttpResource,
  CoreListEnvelop,
  escapeDelimiters,
  isUuid,
} from '@mkp/shared/data-access';
import { map, Observable } from 'rxjs';
import { AccountDto, AccountPostBody } from './account.dto';

@Injectable({ providedIn: 'root' })
export class AccountResource extends BaseHttpResource<
  AccountDto,
  CoreListEnvelop<AccountDto>,
  AccountPostBody
> {
  constructor() {
    super('account');
  }

  fetchByIds(accountIds: string[]): Observable<AccountDto[]> {
    return this.getWithQuery({ filter: getIdsFilter(accountIds) }).pipe(
      map(({ _embedded: { results: accounts } }) => accounts)
    );
  }

  search(searchTerm: string): Observable<AccountDto[]> {
    return this.getWithQuery({
      limit: 20,
      filter: getSearchFilter(searchTerm),
    }).pipe(map(({ _embedded: { results: accounts } }) => accounts));
  }
}

const getIdsFilter = (accountIds: string[]): string =>
  accountIds.map((accountId) => `id==${accountId}`).join(',');

const getSearchFilter = (searchTerm: string): string =>
  isUuid(searchTerm)
    ? `legalEntity.companyProfiles.id==${searchTerm}`
    : searchTerm
        .split(' ')
        .map(
          (word) => `legalEntity.name=@${escapeDelimiters(word)},name=@${escapeDelimiters(word)}`
        )
        .join(';');
