import { authApiActions } from '@mkp/auth/actions';
import { LanguageIso } from '@mkp/shared/data-access';
import {
  createEntityFeature,
  EntityFeatureState,
  FetchState,
  LoadingState,
} from '@mkp/shared/util-state';
import { loginActions } from '@mkp/user/feature-login/actions';
import { passwordlessLoginActions } from '@mkp/user/feature-passwordless-login/actions';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { appHeaderActions, languageEffectsActions } from '@store/actions';
import { onboardingActions } from '@store/actions/settings.actions';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State extends EntityFeatureState<null, { message: string }> {
  language: LanguageIso | null;
  fetchState: FetchState<{ message: string }>;
  isNavigationCollapsed: boolean;
  usernamePasswordLoginPreferred: boolean;
}

const {
  featureKey,
  initialState: initialFeatState,
  updaters,
  selectors,
} = createEntityFeature<State, 'settingsState'>('settingsState');

const initialState: State = {
  ...initialFeatState,
  language: null,
  fetchState: LoadingState.INIT,
  isNavigationCollapsed: false,
  usernamePasswordLoginPreferred: false,
};

const reducer = createReducer<State>(
  initialState,
  on(
    onboardingActions.resendEmailVerificationStart,
    authApiActions.fetchAccessTokenSuccess,
    updaters.setLoading
  ),
  on(onboardingActions.resendEmailVerificationFailure, (state, { error }) =>
    updaters.setError(state, { error })
  ),
  on(onboardingActions.resendEmailVerificationSuccess, updaters.setLoaded),
  on(
    languageEffectsActions.appLanguageInitializedForUser,
    languageEffectsActions.appLanguageInitializedForVisitor,
    appHeaderActions.switchAppLanguage,
    (state, { languageIso }) => ({
      ...state,
      language: languageIso,
    })
  ),
  on(passwordlessLoginActions.linkRequested, (state) => ({
    ...state,
    usernamePasswordLoginPreferred: false,
  })),
  on(loginActions.usernamePasswordSubmitted, (state) => ({
    ...state,
    usernamePasswordLoginPreferred: true,
  }))
);

export { featureKey, initialState, reducer, selectors };

export const settingsReducer: MetaReducer = (
  reducer: ActionReducer<Action>
): ActionReducer<Action> =>
  localStorageSync({
    keys: [featureKey],
    storageKeySerializer: (key) => `jobcloud.${key}`,
    rehydrate: true,
  })(reducer);
