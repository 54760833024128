/**
 * @deprecated use the one in \@mkp/user/models instead
 */
export enum UserTitle {
  ms = 'ms',
  mr = 'mr',
  diverse = 'diverse',
}

/**
 * @deprecated use the one in \@mkp/user/models instead
 */
export const isUserTitle = (value: string): value is UserTitle =>
  Object.values(UserTitle).includes(value as UserTitle);
