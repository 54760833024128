import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { CreditResource } from '@mkp/shared/data-access';
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { creditApiActions } from '@mkp/credit/actions';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@mkp/shared/ui-library';
import { selectAvailableSlotId } from './slot.selectors';
import { selectVacancyIdFromRoute } from '@app/features/vacancy/store/selectors/vacancy.selectors';
import { formatISO } from 'date-fns';
import { Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { AppConfig } from '@app/config/app.config';

/**
 * @description Effect to handle redeeming a slot.
 * Takes a slot code from the action, gets vacancy and slot IDs from the store,
 * and calls the API to redeem the slot.
 */
export const redeemSlot = createEffect(
  (
    actions$ = inject(Actions),
    creditResource = inject(CreditResource),
    store = inject(Store),
    snackbarService = inject(SnackbarService)
  ) =>
    actions$.pipe(
      ofType(creditApiActions.redeemSlot),
      switchMap(({ slotCode }) =>
        of(slotCode).pipe(
          withLatestFrom(
            store.select(selectVacancyIdFromRoute),
            store.select(selectAvailableSlotId(slotCode))
          ),
          filter(([_, vacancyId, slotId]) => Boolean(slotId) && Boolean(vacancyId)),
          switchMap(([_, vacancyId, slotId]) => {
            const formattedStartDate = formatISO(new Date());

            return creditResource
              .redeemSlot(slotId as string, vacancyId as string, formattedStartDate)
              .pipe(
                map(() => creditApiActions.redeemSlotSucceed()),
                catchError((error) => {
                  snackbarService.showError('ORDER_SUMMARY.ERRORS.SLOT_REDEEM');
                  return of(creditApiActions.redeemSlotFailed({ error }));
                })
              );
          })
        )
      )
    ),
  { functional: true }
);

/**
 * @description Effect to handle successful slot redemption.
 * Shows a success message and navigates to the order complete page.
 */
export const handleRedeemSlotSuccess = createEffect(
  (
    actions$ = inject(Actions),
    snackbarService = inject(SnackbarService),
    router = inject(Router),
    store = inject(Store)
  ) =>
    actions$.pipe(
      ofType(creditApiActions.redeemSlotSucceed),
      concatLatestFrom(() => store.select(selectVacancyIdFromRoute)),
      tap(([_, vacancyId]) => {
        if (!vacancyId) return;

        snackbarService.show('ORDER_SUMMARY.SUCCESS.SLOT_REDEEM');

        router.navigate([
          '/vacancy/publish',
          vacancyId,
          'create',
          'summary',
          AppConfig.routes.orderComplete,
        ]);
      })
    ),
  { functional: true, dispatch: false }
);
