import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { CreditDto } from '@mkp/shared/data-access';
import { creditApiActions } from '@mkp/credit/actions';
import { CreditOverviewActions } from '@mkp/credit/feature-credit-overview/actions';
import { ProductOfferingActions } from '@mkp/product/feature-product-offering/actions';
import { productSummaryPageActions } from '@features/product-summary/store/actions';
import { creditManagementPageActions } from '@mkp/credit/actions';

interface State extends EntityState<CreditDto> {
  fetchState: FetchState<Error>;
  totalCount: number;
}

const adapter: EntityAdapter<CreditDto> = createEntityAdapter<CreditDto>();

export const initialState: State = adapter.getInitialState({
  fetchState: LoadingState.INIT,
  totalCount: 0,
});

const reducer = createReducer(
  initialState,
  on(
    CreditOverviewActions.enter,
    ProductOfferingActions.enter,
    productSummaryPageActions.enter,
    creditManagementPageActions.enter,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(creditApiActions.loadCreditTotalCountSucceed, (state, { totalCount }) => ({
    ...state,
    totalCount,
  })),

  on(creditApiActions.loadCreditsSucceed, (state, { credits }) =>
    adapter.setAll(credits, { ...state, fetchState: LoadingState.LOADED })
  ),
  on(
    creditApiActions.loadCreditsFailed,
    creditApiActions.loadCreditTotalCountFailed,
    (state, { error }) => ({ ...initialState, error })
  )
);

const feature = createFeature({
  name: 'credit',
  reducer,
});

const { selectAll } = adapter.getSelectors(feature.selectCreditState);

export const creditFeature = { ...feature, selectAll };

export { State as CreditState };
