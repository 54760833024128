import { Application, getATSPlatform, ScreeningQuestion } from '@mkp/application/models';
import { ApplicationDto } from './application.dto';

export const mapApplicationDtoToModel = (dto: ApplicationDto): Application => ({
  ...dto,
  platform: getATSPlatform(dto.platform),
  screeningQuestions: dto.screeningQuestions ? sortScreeningQuestions(dto.screeningQuestions) : [],
  createdAt: new Date(dto.createdAt),
  updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : null,
});

const sortScreeningQuestions = (screeningQuestions: ScreeningQuestion[]): ScreeningQuestion[] => [
  ...screeningQuestions.sort(({ index: index1 }, { index: index2 }) => index1 - index2),
];
