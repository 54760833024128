import { CreateUserPayload } from '@mkp/user/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UpdateUser } from '@user/shared/models';

export const userDetailsActions = createActionGroup({
  source: 'User Details',
  events: {
    'Update User Profile': props<{
      updateUser: UpdateUser;
      notification: string | false;
      redirectUrl?: string;
    }>(),
    'Update User': props<{
      updateUser: UpdateUser;
      notification: string | false;
      redirectUrl?: string;
    }>(),
    'Create User On Account': props<{
      createUserPayload: CreateUserPayload;
      successMessage: string;
      errorMessage: string;
    }>(),
    'Reset Password': props<{ email: string; notification: string }>(),
    'Init User Details Page': emptyProps(),
  },
});
