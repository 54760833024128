import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { AbTestStateModule, abTestSyncReducer } from '@mkp/ab-test/state';
import { AccountMembershipStateModule } from '@mkp/account-membership/state';
import { AccountStateModule } from '@mkp/account/state';
import { ApplicationStateModule } from '@mkp/application/state';
import { AuthStateModule } from '@mkp/auth/state';
import { CompanyStateModule } from '@mkp/company/state';
import { VersionStateModule } from '@mkp/core/state';
import { CreditStateModule } from '@mkp/credit/state';
import { DebugStateModule, debugSyncReducer } from '@mkp/debug/state';
import { GdprStateModule } from '@mkp/gdpr/state';
import { LayoutStateModule } from '@mkp/layout/state';
import { TransientAppCacheStateModule } from '@mkp/transient-app-cache/state';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from '@user/store/effects/user.effects';
import { transientAppCacheReducer } from 'libs/transient-app-cache/state/src/lib/transient-app-cache/transient-app-cache.reducer';
import { effects, reducers } from './';
import { settingsReducer } from './reducers/settings.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [
        settingsReducer,
        debugSyncReducer,
        abTestSyncReducer,
        transientAppCacheReducer,
      ],
      runtimeChecks: { strictActionTypeUniqueness: true },
    }),
    EffectsModule.forRoot([...effects, UserEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    AuthStateModule,
    GdprStateModule,
    environment.ngrxDevTools,
    MatDialogModule,
    TransientAppCacheStateModule,
    DebugStateModule,
    CompanyStateModule,
    LayoutStateModule,
    AbTestStateModule,
    AccountStateModule,
    CreditStateModule,
    AccountMembershipStateModule,
    ApplicationStateModule,
    VersionStateModule,
  ],
})
export class RootStoreModule {}
