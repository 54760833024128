import {
  PRODUCT_TRANSLATIONS,
  Product,
  ProductDto,
  ProductFeature,
  featureMapping,
  getCalendarBenefit,
  getCurrency,
  getFirstVariant,
  getPartialFeatures,
  ProductCode,
  ProductInformation,
} from '@mkp/shared/util-product';

export const toViewModel = (productDto: ProductDto): Product | undefined => {
  try {
    const features = (getPartialFeatures(productDto) as ProductFeature[])?.map(mapFeature);

    const translations = PRODUCT_TRANSLATIONS[productDto.code];
    const { originalPrice, price } = getFirstVariant(productDto);
    return {
      slug: productDto.slug,
      variants: productDto.variants,
      calendar: getCalendarBenefit(features),
      longname: getLongName(productDto),
      name: translations.name,
      shortName: translations.shortname,
      // cartDiscounts: productDto.cartDiscounts?.map(mapCardDiscounts),
      code: productDto.code,
      coupon: '',
      currency: getCurrency(productDto),
      description: getDescription(productDto),
      features: features,
      originalPrice: originalPrice,
      price: price,
      // promotion: productDto.promotion,
    };
  } catch (error) {
    console.error((error as Error).message);
  }

  return undefined;
};

const getLongName = ({ code }: ProductDto): string => {
  return PRODUCT_TRANSLATIONS[code]?.longname;
};

const getDescription = ({ code }: ProductDto): string => PRODUCT_TRANSLATIONS[code]?.description;

const mapFeature = (featureDto: ProductFeature): ProductFeature => {
  const { parent, title, description, translation } = featureMapping[featureDto.id];

  return {
    id: featureDto.id,
    // translate the parent feature
    parent,
    // translate the feature title
    title,
    // translate the feature description
    description,
    // translate the feature for comparison table
    translation,
  };
};

export const toProductInformation = (product: ProductDto): ProductInformation => {
  const publicationDaysMapping: Partial<Record<ProductCode, number>> = {
    [ProductCode.JOBSFLEX]: 7,
    [ProductCode.JOBSOFFER22L]: 45,
    [ProductCode.JOBUP24ULTIMATE]: 45,
    [ProductCode.JOBSOFFER22XL]: 60,
    [ProductCode.JOBUP24ULTIMATEPLUS]: 60,
  };

  const publicationDays = publicationDaysMapping[product.code] ?? 31;

  return {
    type: product.code,
    publicationDays,
    name: {
      de: product.name,
      en: product.name,
      fr: product.name,
    },
  };
};
