import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { SkeletonLoaderModule } from '@app/shared/modules/skeleton-loader/skeleton-loader.module';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeComponent, BadgeConfig, BadgeType } from '../../../elements';
import { TabInkBarDirective } from '../tab-ink-bar.directive';
import { TabItemComponent } from '../tab-item/tab-item.component';
import { TabSize } from '../tab.config';

@Component({
  selector: 'mds-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrl: './tab-header.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TabInkBarDirective,
    DataTestDirective,
    MatIconModule,
    BadgeComponent,
    SkeletonLoaderModule,
    AssetComponent,
    TranslateModule,
  ],
})
export class TabHeaderComponent implements OnChanges, AfterViewInit {
  @Input() tabs!: QueryList<TabItemComponent>;
  @Input() icon?: string;
  @Input({ required: true }) disabled!: boolean;
  @Input({ required: true }) size!: TabSize;
  @Output() handleClick = new EventEmitter<{ index: number; action: string }>();
  @ViewChild(TabInkBarDirective, { static: true }) inkBar!: TabInkBarDirective;
  @ViewChild('tabHeaderWrapper') tabHeaderWrapper!: ElementRef;
  @ViewChildren('tabItem') tabItems!: QueryList<ElementRef>;

  classes = '';
  badgeConfig: BadgeConfig = { type: BadgeType.Small };
  private readonly renderer = inject(Renderer2);
  private readonly destroy = inject(DestroyRef);

  ngOnChanges({ size }: SimpleChanges): void {
    if (size) {
      this.badgeConfig = { type: getBadgeType(this.size) };
      this.classes = getSizeClasses(this.size);
    }
  }

  ngAfterViewInit(): void {
    const activeTab = this.tabs.find((tab) => tab.active);
    this.alignInkBarToSelectedTab(activeTab?.index || 0);
    this.onScroll();

    this.alignInkBarOnTabChange();
  }

  selectTab(tab: TabItemComponent, index: number) {
    tab.active = true;
    this.alignInkBarToSelectedTab(index);

    this.handleClick.emit({ index, action: tab.action });
  }

  onScroll() {
    if (!this.tabHeaderWrapper?.nativeElement) {
      return;
    }

    const isEnd =
      this.tabHeaderWrapper.nativeElement.offsetWidth +
        this.tabHeaderWrapper.nativeElement.scrollLeft ===
      this.tabHeaderWrapper.nativeElement.scrollWidth;

    isEnd
      ? this.renderer.removeClass(this.tabHeaderWrapper.nativeElement, 'overflow-opacity')
      : this.renderer.addClass(this.tabHeaderWrapper.nativeElement, 'overflow-opacity');
  }

  private alignInkBarOnTabChange() {
    this.tabs.forEach((tab) => {
      tab.tabChanged.pipe(takeUntilDestroyed(this.destroy)).subscribe(() => {
        // wait for it to render
        setTimeout(() => {
          const activeTab = this.tabs.find((tab) => tab.active);
          this.alignInkBarToSelectedTab(activeTab?.index || 0);
        });
      });
    });
  }

  private alignInkBarToSelectedTab(tabIndex: number): void {
    if (!this.inkBar) {
      return;
    }

    const selectedTab = this.tabItems && tabIndex > -1 ? this.tabItems.toArray()[tabIndex] : null;

    if (selectedTab?.nativeElement) {
      this.inkBar.alignToElement(selectedTab.nativeElement);
    }
  }
}

const getBadgeType = (tabSize: TabSize): BadgeType =>
  tabSize === TabSize.Large ? BadgeType.Large : BadgeType.Small;
const getSizeClasses = (tabSize: TabSize): string =>
  tabSize === TabSize.Small ? ` tab-small body-s` : ` tab-large body-default`;
