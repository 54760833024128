import { ApiLink, LanguageCode } from '@mkp/shared/data-access';

export enum UserType {
  RegularUser = 'regular-user',
  MultiUser = 'multi-user',
  BoUser = 'bo-user',
}

/**
 * @deprecated user the one in \@mkp/user/models instead
 */
export enum UserDisplayType {
  CustomerService = 'customerService',
  CustomerJobs = 'customerJobs',
  CustomerJobUp = 'customerJobup',
  CustomerJobcloud = 'customerJobcloud',
  CustomerJobScout24 = 'customerJobscout24',
}

export enum UserRole {
  Admin = 'USER_ROLE_ADMIN',
  SuperAdmin = 'USER_ROLE_SUPER_ADMIN',
}

export interface UserSettings {
  displayType: UserDisplayType;
  language: LanguageCode;
  marketingConsentAcceptedAt: string | null;
  publicationAiUsageConsent: boolean | null;
  _type: 'userSettings';
}

export interface UserLinks {
  self: ApiLink<'GET'>;
  sendVerificationEmail: ApiLink<'POST'>;
  /** @Note BE will update profile-picture to profilePicture soon */
  'profile-picture': ApiLink<'GET'>;
  updatedBy: ApiLink<'GET'>;
  update: ApiLink<
    'PATCH',
    | 'settings.marketingConsentAcceptedAt'
    | 'settings.language'
    | 'settings.displayType'
    | 'gtcAcceptedAt'
    | 'profilePictureMediaApiId'
    | 'phoneNumber'
    | 'lastName'
    | 'firstName'
    | 'title'
    | 'email'
  >;
}

/**
 * @deprecated use the one in \@mkp/user/models instead
 */
export enum UserTitle {
  Ms = 'ms',
  Mr = 'mr',
  Diverse = 'diverse',
}
